<template>
  <v-row justify="center" align="center" class="ma-auto h-100">
    <v-col cols="auto">
      <v-progress-circular color="primary" :indeterminate="true" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheLoading',
}
</script>
