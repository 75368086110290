class TokenService {
  #TOKEN_KEY = 'access_token'
  #ACCESS_TOKEN_EXPIRES = 'access_token_expires'

  getToken() {
    return localStorage.getItem(this.#TOKEN_KEY)
  }

  saveToken(accessToken) {
    localStorage.setItem(this.#TOKEN_KEY, accessToken)
  }

  removeToken() {
    localStorage.removeItem(this.#TOKEN_KEY)
  }

  getAccessTokenExpires() {
    return localStorage.getItem(this.#ACCESS_TOKEN_EXPIRES)
  }

  saveAccessTokenExpires(refreshToken) {
    localStorage.setItem(this.#ACCESS_TOKEN_EXPIRES, refreshToken)
  }

  removeAccessTokenExpires() {
    localStorage.removeItem(this.#ACCESS_TOKEN_EXPIRES)
  }
}

export const tokenService = new TokenService()
