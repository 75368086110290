<template>
  <the-container>
    <h2 class="mb-6">Календарь</h2>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <v-row v-else class="pa-0 ma-0 full-calendar">
      <FullCalendar :options="calendarOptions">
        <template #eventContent="arg">
          <b>{{ arg.event.title }}</b>
        </template>
      </FullCalendar>
    </v-row>
    <v-dialog v-model="dialog">
      <v-card v-if="currentEvent" width="600" class="pa-0 ma-0">
        <v-card-title class="pa-4 pb-0">{{ currentEvent.title }}</v-card-title>
        <v-card-text class="pa-4">
          <v-row v-if="currentEvent.status" class="pa-0 ma-0"
            >Статус: {{ currentEvent.status }}</v-row
          >
          <v-row v-if="currentEvent.description" class="pa-0 ma-0"
            >Описание: {{ currentEvent.description }}</v-row
          >
          <v-row v-if="currentEvent.start || currentEvent.end" class="pa-0 ma-0"
            >{{ formatDate(currentEvent.start) }} - {{ formatDate(currentEvent.end) }}</v-row
          >
        </v-card-text>
        <v-card-actions>
          <v-row :no-gutters="true" justify="end">
            <v-btn color="primary" @click="handleShowMoreClick"> Подробнее </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </the-container>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import TheContainer from '@/components/TheContainer.vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import 'qalendar/dist/style.css'
import { api } from '@/api/Api'
import moment from 'moment'
import ruLocale from '@fullcalendar/core/locales/ru'
import router from '@/router'

const isLoading = ref(false)
const dialog = ref(false)
const currentEvent = ref()
const calendarOptions = ref({
  plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
  initialView: 'dayGridMonth',
  locale: ruLocale,
  events: [],
  views: {
    multiMonthFourMonth: {
      type: 'multiMonth',
      duration: { months: 4 },
    },
  },
  eventColor: '#1A2574',
  editable: true,
  selectable: true,
  selectMirror: true,
  weekends: true,
  eventClick: handleEventClick,
})
const points = ref([])

function formatDate(date) {
  if (!date) {
    return ''
  }
  return moment(date).format('DD.MM.YYYY')
}

function handleShowMoreClick() {
  return router.push(`/tasks/${currentEvent.value?.id}`)
}

function handleEventClick(data) {
  currentEvent.value = events.value?.find((el) => el.id == data.event.id)
  dialog.value = true
}
const colors = ref([
  '#2C6DB2',
  '#1A2574',
  '#000000',
  '#93989F',
  '#9EAFC4',
  '#B5CCE4',
  '#FFD439',
  '#E74E0F',
  '#E54949',
  '#3F9946',
])
const events = ref()

function prepareData(data) {
  events.value = data.map((point) => {
    const color = colors.value[Math.floor(Math.random() * colors.value.length)]
    return {
      title: point.name,
      start: point.dateFrom || moment(new Date()).add(-4, 'day').format('YYYY-MM-DD HH:MM'),
      end: point.dateTo || moment(new Date()).format('YYYY-MM-DD HH:MM'),
      color: color || 'green',
      status: point.status.name,
      description: point.description,
      id: point.id,
    }
  })
  calendarOptions.value.events = events.value
}

async function getAllPoints() {
  isLoading.value = true

  try {
    await api
      .projects()
      .getAllPoints()
      .then((data) => {
        points.value = data
        prepareData(data)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(getAllPoints)
</script>
<style lang="scss">
.fc {
  width: 100%;
}

.full-calendar .fc.fc-theme-standard .fc-toolbar .fc-button:disabled,
.full-calendar .fc.fc-theme-standard .fc-toolbar .fc-button {
  background: #1a2574;
}

.full-calendar .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #1a2574;
  border-color: #1a2574;
  opacity: 0.8;
}
</style>
