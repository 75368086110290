import { axiosCustom } from '@/plugins/axios'

export class User {
  getCurrentUserInfo() {
    return axiosCustom.get('/api/user/info').then((response) => response.data)
  }

  getUserInfoById(userId) {
    return axiosCustom.get(`/api/user/${userId}`).then((response) => response.data)
  }

  updateInfoByUser(userId, form) {
    return axiosCustom.put(`/api/user/${userId}`, form).then((response) => response.data)
  }

  setGroupToUser(form) {
    return axiosCustom.post('/api/user/setgroups', form).then((response) => response.data)
  }

  getUsers({ page = 1, pageSize = 20, group_id, email, phone, city, work_company, name }) {
    const params = new URLSearchParams({
      page: page,
      pageSize: pageSize,
      ...(group_id !== undefined && { group_id: group_id }),
      ...(email !== undefined && { email: email }),
      ...(phone !== undefined && { phone: phone }),
      ...(city !== undefined && { city: city }),
      ...(work_company !== undefined && { work_company: work_company }),
      ...(name !== undefined && { name: name }),
    }).toString()

    return axiosCustom.get(`/api/user?${params}`).then((response) => response.data)
  }

  getUsersByGroup(group, page = 1) {
    const params = new URLSearchParams({
      group_id: group,
      pageSize: 20,
      page,
    }).toString()

    return axiosCustom.get(`/api/user?${params}`).then((response) => response.data)
  }
}
