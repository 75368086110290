<template>
  <v-textarea
    :id="`inputCustom${id}`"
    v-model="inputModel"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :rules="rulesCommon"
    :maxlength="maxLength"
    :counter="maxLength"
    :placeholder="placeholder"
    :readonly="readOnly"
    :error-messages="errorMessages"
    density="comfortable"
    variant="outlined"
    :hide-details="hideDetails"
    @change="changeValueInput"
  />
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'

const props = defineProps({
  ...baseProps,
})
const emit = defineEmits(baseEmit)

const { maxLength, rulesCommon, inputModel } = useForm(props, emit)

function changeValueInput(e) {
  emit('change-value', { id: props.id, value: e.target.value })
}
</script>
