<template>
  <v-text-field
    :id="`inputCustom${id}`"
    v-model="inputModel"
    v-mask="getMask()"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :maxlength="maxLength"
    :counter="maxLength"
    :rules="rulesCommon"
    :readonly="readOnly"
    :hide-details="hideDetails"
    :error-messages="errorMessages"
    color="primary"
    variant="outlined"
    class="text-pre-wrap"
    @change="changeValueInput"
  />
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'

const props = defineProps({
  ...baseProps,
})
const emit = defineEmits(baseEmit)

const getMask = () => {
  if (props.isPhone) {
    return '+7 (###) ###-##-##'
  }

  return ''
}

const { maxLength, rulesCommon, inputModel } = useForm(props, emit)

function changeValueInput(e) {
  let value = e.target.value
  if (props.isUrl && e.target.value) {
    if (!value?.includes('http')) {
      value = 'https://' + e.target.value
      inputModel.value = value
    }
  }

  emit('change-value', { id: props.id, value })
}
</script>
