import { axiosNotifications } from '@/plugins/axios'

export class Message {
  getBatchMessages(params) {
    return axiosNotifications.get(`/messages?${params}`).then((response) => response.data)
  }

  sendBatchMessages(form) {
    return axiosNotifications.post('/messages/batch', form).then((response) => response.data)
  }
}
