<template>
  <g>
    <path
      d="M28.668 1.469h6.46a4.085 4.085 0 014.079 4.086v22.402c0 2.113-1.828 3.941-3.937 3.941h-6.461a4.083 4.083 0 01-4.079-4.085V5.413c0-2.113 1.688-3.945 3.938-3.945zm0 26.77h6.46V5.128h-6.46zm0 0M3.793 1.469h14.898a3.782 3.782 0 013.793 3.804v22.684c0 2.113-1.687 3.8-3.793 3.8H3.793A3.781 3.781 0 010 27.958V5.273A3.782 3.782 0 013.793 1.47zm.281 26.77h14.477V5.128H4.074zm0 0M45.39 31.898h-4.078V1.468h4.079v12.68h6.46V1.468h4.079v30.43h-4.078V17.813H45.39zm0 0M59.863 35h-2.672l-.421-6.762h2.25l3.09-23.25h-1.262l-.844-3.66h9.695c1.828 0 3.371 1.406 3.371 3.238l1.547 23.532h2.25l-.703 6.761h-2.953l-.7-3.101H60.567zm2.95-6.762h7.87L69.56 5.128h-3.653c-.703 4.794-3.094 23.11-3.094 23.11zm0 0"
      fill="#2d2d3f"
    />
    <path
      d="M89.09 31.898h-4.074V1.468h4.074v12.68h6.465V1.468h4.074v30.43h-4.074V17.813H89.09zm0 0M101.316 1.469H112.7v3.66h-3.37v26.914h-4.079V5.129h-3.934zm0 0M124.926 11.473l-6.465 20.425h-4.074V1.468h4.074V22.04l6.465-20.57H129v30.43h-4.074zm0 0"
      fill="#e74e0f"
    />
    <path d="M13.363 35H9.29L9.297.094h4.074zm0 0" fill="#2d2d3f" />
  </g>
</template>

<script setup></script>
