<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <v-col v-else class="pa-0 ma-0">
    <temlate v-if="notes.length">
      <v-card
        v-for="item in notes"
        :key="`note-${item.id}`"
        elevation="2"
        hover
        class="ma-0 pa-4 mb-2 d-flex align-center justify-space-between"
      >
        <v-col class="pa-0 ma-0"
          >Черновик "{{ item.pointId ? item.pointName : item.name && `(${item.name})` }}"</v-col
        >
        <v-col class="pa-0 ma-0 d-flex justify-end">
          <RouterLink
            :to="item.pointId ? `/tasks/${item.pointId}` : `/programs/create/${item.code1C}`"
          >
            Перейти
          </RouterLink>
        </v-col>
      </v-card>
    </temlate>
    <v-row v-else class="justify-center align-center py-6 text-grey"> Список пуст </v-row>
  </v-col>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api/Api'

const isLoading = ref(false)
const notes = ref([])

async function getNotes() {
  isLoading.value = true
  try {
    await api
      .questionnaires()
      .getApplicationNotes()
      .then((data) => {
        notes.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(getNotes)
</script>
