<template>
  <v-text-field
    :id="`inputCustom${id}`"
    v-model="inputModel"
    :hint="descr"
    :persistent-hint="Boolean(descr)"
    :maxlength="maxLength"
    :counter="maxLength"
    :min="min"
    :rules="rulesCommon"
    :readonly="readOnly"
    :error-messages="errorMessages"
    color="primary"
    variant="outlined"
    type="number"
    class="text-pre-wrap"
    :hide-details="hideDetails"
    @change="changeValueInput"
    @keydown="clearString"
    @keypress="inputHandler"
  />
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'

const props = defineProps({
  ...baseProps,
  onlyNumbers: {
    type: Boolean,
    default: false,
  },
  isFloatNumber: {
    type: Boolean,
    default: true,
  },
  min: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits(baseEmit)

const { maxLength, rulesCommon, inputModel } = useForm(props, emit)

const inputHandler = (e) => {
  const { value, maxLength } = e.target
  if (maxLength && maxLength !== -1 && String(value).length >= maxLength) {
    e.preventDefault()
    return
  }
}

function clearString(e) {
  if (props.isFloatNumber && !props.onlyNumbers) {
    if (['+', '-', 'e', '.', ' '].includes(e.key)) e.preventDefault()
  }

  if (!props.onlyNumbers) {
    return
  }

  if (['+', '-', 'e', '.', ' '].includes(e.key)) e.preventDefault()
}

function changeValueInput(e) {
  emit('change-value', { id: props.id, value: e.target.value })
}
</script>
