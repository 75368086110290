<template>
  <v-menu
    :close-on-content-click="false"
    anchor="bottom end"
    max-width="300"
    max-height="300"
    class="w-100"
    rounded
  >
    <template #activator="{ props }">
      <v-badge
        v-if="notificationsList.length"
        color="red"
        :content="notificationsList.length"
        bordered
        offset-y="10"
        offset-x="6"
        v-bind="props"
      >
        <v-btn
          id="notification"
          color="primary"
          icon="mdi-bell-outline"
          variant="text"
          class="user"
        />
      </v-badge>
      <v-btn
        v-else
        v-bind="props"
        id="notification"
        color="primary"
        icon="mdi-bell-outline"
        variant="text"
        class="user"
      />
    </template>
    <v-card class="w-100" min-width="300">
      <v-card-text>
        <div>
          <div class="font-weight-bold mb-2">Новые уведомления</div>
          <div v-if="!notificationsList.length" class="py-2 px-0">Нет новых уведомлений</div>

          <div v-for="(item, index) in notificationsList" :key="index">
            <v-divider class="my-3" />

            <v-row
              class="ma-0 px-0 flex-nowrap w-100 justify-space-between align-center justify-space-between"
            >
              <v-col class="flex-grow-1 pa-0">
                <div
                  v-if="item.message"
                  :id="`text-notification-${index}`"
                  class="text-body-2 line-height-1 mb-2"
                >
                  {{ getShortTitle(item.message) }}
                </div>
                <v-tooltip
                  :activator="`#text-notification-${index}`"
                  location="right"
                  :max-width="400"
                >
                  {{ item.message }}
                </v-tooltip>
                <div class="text-grey text-caption">
                  {{ item.date }}
                </div>
              </v-col>
              <v-col class="px-0 flex-grow-0 pa-0 ml-2">
                <v-btn
                  id="notification"
                  :color="item.new !== 'Y' ? 'grey' : 'blue'"
                  icon="mdi-bell-outline"
                  variant="text"
                  class="user"
                  @click="() => changeNotifyStatus(item.id)"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { DEFAULT_ID_PROJECT } from '@/constants'

const notificationsList = ref([])
const isLoading = ref(false)

async function getNewNotifications() {
  isLoading.value = true
  try {
    const data = await api.notifications().getNewNotifications()
    notificationsList.value = data?.data?.items

    setTimeout(() => {
      getNewNotifications()
    }, 60000)
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getShortTitle(str) {
  return `${str.substr(0, 100)}...`
}

async function changeNotifyStatus(id) {
  const data = new FormData()
  data.append('message[]', id)
  data.append('project', DEFAULT_ID_PROJECT)
  await api
    .notifications()
    .readNotify(data)
    .then(() => {
      getNewNotifications()
    })
}

onMounted(getNewNotifications)
</script>
