<template>
  <v-btn color="primary" size="small" class="ma-2" @click="openDialog"> Настроить правила </v-btn>
  <v-dialog v-model="dialog">
    <v-form ref="formRef">
      <v-card width="500">
        <v-card-text>
          <v-row :no-gutters="true">
            <v-col>
              <span>Обязательное поле</span>
              <v-checkbox
                v-model="ruleProp.required"
                true-value='["required"]'
                false-value=""
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row :no-gutters="true">
            <v-col>
              <span>Максимальная длинна строки</span>
              <v-text-field
                v-model="ruleProp.max"
                color="primary"
                variant="outlined"
                density="compact"
                type="number"
                :readonly="readOnly"
                placeholder="Введите число"
                clearable
              />
            </v-col>
          </v-row>
          <v-row v-if="false" :no-gutters="true">
            <v-col>
              <span>Минимальная и максимальная длинна строки</span>
              <v-text-field
                v-model="ruleProp.minMax"
                color="primary"
                variant="outlined"
                density="compact"
                placeholder="Введите числа"
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="save"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { findRuleProperty, useForm } from '@/composables/form/useForm'
import { ref } from 'vue'

const props = defineProps({
  ...baseProps,
})
const emit = defineEmits(baseEmit)

const dialog = ref(false)
const formRef = ref(null)
const ruleProp = ref({
  max: setDef('string'),
  required: setDef('required'),
})

function setDef(type) {
  const value = props.default_value || props.rules
  if (typeof value === 'string') {
    return value?.split(', ').find((value) => value.includes(type))
  }

  const pr = {
    rules: value,
  }
  const item = findRuleProperty(pr, type)

  if (!item) {
    return ''
  }

  if (type === 'required') {
    return '["required"]'
  }

  return item[1]?.max ?? ''
}
function openDialog() {
  dialog.value = true
}
async function save() {
  const { valid } = await formRef.value.validate()
  if (!valid) {
    return
  }

  const arr = Object.entries(ruleProp.value).map(([key, value]) => {
    if (!value) {
      return
    }

    switch (key) {
      case 'required': {
        return value
      }
      case 'max': {
        if (!value) {
          return null
        }

        return `["string",{"max": "${value}"}]`
      }
    }
  })

  inputModel.value = `[${arr.filter(Boolean).join(', ')}]`

  dialog.value = false
}

const { inputModel } = useForm(props, emit)
</script>
