import { axiosWinnerUser } from '@/plugins/axios'

export class Sign {
  signExpenseStructure(id) {
    return axiosWinnerUser
      .patch(`/v1/report/${id}/sign-expense-structure`)
      .then((response) => response.data)
  }

  signCoFinance(id) {
    return axiosWinnerUser
      .patch(`/v1/report/${id}/sign-co-finance-structure`)
      .then((response) => response.data)
  }

  signImplementationProgram(reportId) {
    return axiosWinnerUser
      .patch(`GET /v1/report/${reportId}/implementation-program`)
      .then((response) => response.data)
  }
}
