<template>
  <v-expansion-panels :key="projects.length" class="flat-panel" accordion>
    <template v-for="project in projects">
      <v-expansion-panel
        v-if="getPointsProject(project.id) && getPointsProject(project.id).length"
        :key="project.id"
        elevation="0"
        class="mb-md-4 mb-2"
      >
        <v-expansion-panel-title class="text-subtitle-2 font-weight-black">
          <div class="d-flex flex-column">
            <div class="w-100">{{ project?.template?.name }} - {{ project?.name }}</div>
            <span class="text-grey d-flex w-100">ID: {{ project.id }}</span>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pb-md-2">
          <v-col
            v-for="point in getPointsProject(project.id)"
            class="pa-0 ma-0 my-2 my-md-6 w-100 justify-center align-center bordered-item"
          >
            <v-row
              class="pa-2 ma-0 align-md-center align-start justify-start justify-md-space-between flex-md-row flex-column"
            >
              <v-col class="pa-2 pa-md-5 ma-0 font-weight-bold" cols="12" md="4" lg="4">
                <div>{{ point.name }}</div>
                <div
                  class="text-caption text-grey expertise-description"
                  v-html="point.description"
                />
              </v-col>
              <v-col class="pa-2 pa-md-5 ma-0 text-grey" cols="12" md="6" lg="6">
                <div class="font-weight-black">
                  Статус: <span>{{ point.status.name }}</span>
                </div>
                <div
                  v-if="point.startDate || point.deadline"
                  :class="[getStatusColor(point), 'ma-0 pa-0 text-caption']"
                >
                  {{ getFormatDateTime(point.startDate) }}
                  <template v-if="getFormatDateTime(point.deadline)">-</template>
                  {{ getFormatDateTime(point.deadline) }}
                </div>
              </v-col>
              <v-col
                class="pa-2 pa-md-5 text-start text-md-end ma-0 font-weight-regular justify-start justify-md-end"
                cols="12"
                md="2"
                lg="2"
              >
                <RouterLink :to="`/tasks/${point.id}`">
                  {{ point.canEdit ? 'Редактировать' : 'Перейти' }}
                </RouterLink>
              </v-col>
              <!--              <v-col class="pa-5 ma-0 font-weight-regular" cols="2">-->
              <!--                <RouterLink :to="`/calendar`"> Календарь </RouterLink>-->
              <!--              </v-col>-->
            </v-row>
          </v-col>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </template>
    <v-row v-if="!hasPoints" class="justify-center align-center py-6 text-grey">
      Список пуст
    </v-row>
  </v-expansion-panels>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import moment from 'moment'
import { api } from '@/api/Api'
import { getFormatDateTime } from '../../helpers'

const props = defineProps({
  projects: {
    type: Array,
    default: () => [],
  },
})

const points = ref([])
const isLoading = ref(false)
const getStatusColor = (point) => {
  const date = new Date()
  if (
    !point.startDate ||
    !point.deadline ||
    moment(date).isBefore(point.startDate) ||
    point.status.sort >= 1000
  ) {
    return 'text-grey'
  }

  const deadline = moment(point.deadline)
  const currentDate = moment()

  const difference = deadline.diff(currentDate, 'hours')

  if (difference <= 24 && difference > 0) {
    return 'text-orange'
  }

  if (moment(date).isAfter(point.startDate) && moment(date).isBefore(point.deadline)) {
    return 'text-green'
  }

  if (moment(date).isAfter(point.deadline) && point.status.sort < 1000) {
    return 'text-red'
  }

  if (moment(date).isAfter(point.startDate) && moment(date).isBefore(point.deadline)) {
    return 'text-green'
  }

  return 'text-grey'
}

const hasPoints = computed(() => {
  let pointsCount = 0
  props.projects.forEach((el) => {
    pointsCount += getPointsProject(el.id)?.length
  })

  return pointsCount
})

const getExpiredPoints = async () => {
  isLoading.value = true
  try {
    await api
      .projects()
      .getExpiredPoints()
      .then((data) => {
        points.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getPointsProject(projectId) {
  return points.value?.filter((el) => el.projectId === projectId)
}

onMounted(getExpiredPoints)
</script>
<style lang="scss">
.bordered-item {
  border: 1px solid var(--border-th-table);
}
</style>
