import { axiosCustom } from '@/plugins/axios'

export class Contest {
  getContests(page = 1, pageSize = 20, type = null) {
    const requestParams = {
      page,
      pageSize,
    }

    if (type !== null) {
      requestParams.type = type
    }

    const params = new URLSearchParams({ ...requestParams }).toString()
    return axiosCustom.get(`/api/contest?${params}`).then((response) => response.data)
  }

  getContest(id) {
    return axiosCustom.get(`/api/contest/${id}`).then((response) => response.data)
  }

  createContest(form) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axiosCustom.post('/api/contest', form, { headers }).then((response) => response.data)
  }

  updateContest(id, form) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axiosCustom
      .put(`/api/contest/${id}`, form, { headers })
      .then((response) => response.data)
  }

  setManagerToContest(form) {
    return axiosCustom.post('/api/contest/addmanager', form).then((response) => response.data)
  }

  deleteManagerToContest(form) {
    return axiosCustom.post('/api/contest/delmanager', form).then((response) => response.data)
  }

  setMonitorToContest(form) {
    return axiosCustom.post('/api/contest/addmonitor', form).then((response) => response.data)
  }

  setExpertToContest(form) {
    return axiosCustom.post('/api/contest/addexpert', form).then((response) => response.data)
  }

  deleteMonitorToContest(form) {
    return axiosCustom.post('/api/contest/delmonitor', form).then((response) => response.data)
  }

  deleteExpertToContest(form) {
    return axiosCustom.post('/api/contest/delexpert', form).then((response) => response.data)
  }

  setStatusForContest(form) {
    return axiosCustom.post('/api/contest/setuserstatus', form).then((response) => response.data)
  }
}
