<template>
  <v-layout>
    <TheHeader :tabs="tabs" />
    <div class="main-container w-100">
      <v-main class="main content">
        <router-view :key="$route.fullPath" />
      </v-main>
      <div class="footer">
        <TheFooter />
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'DefaultLayout',
}
</script>
<script setup>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ADMIN, MANAGER, MONITOR_CONTENT, USER, MONITOR, EXPERT } from '@/enums/roles'

const store = useStore()
const roles = computed(() => store.getters['user/currentRoles'])

const tabsProgramms = [
  {
    sort: 1,
    title: 'Текущие конкурсы',
    to: '/programs',
  },
]

const tabsTasks = [
  {
    sort: 2,
    title: 'Мои задачи',
    to: '/tasks',
  },
]

const tabsToAdmin = [
  {
    sort: 3,
    title: 'Пользователи и роли',
    to: '/users',
  },
]

const tabs = computed(() => {
  let tabs = []
  roles.value?.forEach((role) => {
    if (role === ADMIN) {
      tabs = [...tabs, ...tabsToAdmin]
    }

    if (role === USER) {
      tabs = [...tabs, ...tabsTasks, ...tabsProgramms]
    }

    if (role === MANAGER || role === EXPERT) {
      tabs = [...tabs, ...tabsProgramms, ...tabsTasks]
    }

    if (role === MONITOR || role === MONITOR_CONTENT) {
      tabs = [...tabs, ...tabsTasks]
    }
  })

  return removeDuplicatesByKey(tabs, 'to')?.sort((a, b) => a.sort - b.sort)
})

function removeDuplicatesByKey(arr, key) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((obj) => obj[key] === item[key]),
  )
}

onMounted(() => {
  store.dispatch('group/getGroupSystem')
})
</script>

<style lang="scss" scoped>
.main {
  height: auto;
  background: var(--light-background);
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.main-container {
  display: flex;
  flex-direction: column;
}
</style>
