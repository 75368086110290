import { inject, provide, ref } from 'vue'

export function useLoading() {
  const isShow = ref(false)

  function showLoading(value = true) {
    isShow.value = value
  }

  provide('isShow', isShow)

  const isShowInject = inject('isShow')

  return {
    isShow,
    isShowInject,
    showLoading,
  }
}
