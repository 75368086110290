import { axiosWinnerUser } from '@/plugins/axios'

export class Tickets {
  createTicket(projectId, status) {
    return axiosWinnerUser
      .post(`/v1/projects/ticket`, { projectId, status })
      .then((response) => response.data)
  }

  getTicket(ticketId) {
    return axiosWinnerUser.get(`/v1/projects/ticket/${ticketId}`).then((response) => response.data)
  }

  createProgramChangeRequest(ticketId, data) {
    return axiosWinnerUser
      .patch(`/v1/projects/ticket/${ticketId}/blank`, data)
      .then((response) => response.data)
  }

  editStatusChangeRequest(ticketId, data, status) {
    return axiosWinnerUser
      .patch(`/v1/projects/ticket/${ticketId}/${status}`, data)
      .then((response) => response.data)
  }

  signedProgram(ticketId, fileId) {
    return axiosWinnerUser
      .post(`/v1/projects/ticket-signature`, { ticketId, fileId })
      .then((response) => response.data)
  }
}
