<template>
  <div>
    <v-tabs v-model="tab" class="mb-6" :key="tab" :show-arrows="sm || xs">
      <v-tab
        v-for="item in tabs"
        :key="item.id"
        selected-class="border-bottom"
        class="text-without-transform font-weight-bold text-caption"
        @click="() => $emit('tab-click', tab)"
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, index) in tabs"
        :key="`tab-item-${item.id}-${route.query?.tab}-${index}-${points.length}`"
      >
        <div v-if="tab === index" flat>
          <Component
            :is="item.component"
            v-bind="item"
            :quarter-id="item?.quarterId"
            :projects="projects"
            :points="points"
            :reports="reports"
            :quarter-type="item?.quarterType"
            :centers-nti="centersNti"
            :is-annual-report="item?.isAnnualReport"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router/dist/vue-router'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'

const { sm, xs } = useDisplay()

const props = defineProps({
  tabs: {
    type: Array,
    default: () => [],
    route: useRoute(),
  },
  projects: {
    type: Array,
    default: () => [],
  },
  centersNti: {
    type: Array,
    default: () => [],
  },
  activeTab: {
    type: Number,
    default: 0,
  },
  reports: {
    type: Array,
    default: () => [],
  },
  points: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const tab = ref(null)
const route = useRoute()
const router = useRouter()

onMounted(() => {
  if (+route.query?.tab) {
    tab.value = +route.query?.tab
  }
})

watch(route.query?.tab, () => {
  if (+route.query?.tab && tab.value !== +route.query?.tab) {
    router.replace(`/nti-information?tab=${tab.value}`)
  }
})

onMounted(() => {
  if (props.activeTab) {
    tab.value = +props.activeTab
  }
})
</script>
<style lang="scss">
.border-bottom {
  color: rgb(var(--v-theme-primary)) !important;
  border-bottom: 2px solid rgb(var(--v-theme-primary)) !important;
}

.tabs-head {
  border-bottom: 2px solid var(--border-tab-head);
}

.text-without-transform {
  text-transform: none;
}
</style>
