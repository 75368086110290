export const DEFAULT_ID_PROJECT = process.env.VUE_APP_PROJECT_ID

export const CONTEXT_CODES = {
  APPLICATION: 'cabinet_user_concurs',
}

export const ROUTE_PAGE_APPLICATION_FOR_USER = 'my-orders'
export const ROUTE_PAGE_APPLICATION_FOR_MANAGER = 'orders'
export const ROUTE_OPEN_HISTORY_APPLICATION_FOR_USER = '/my-orders?chat='
export const PROJECT_CARD_STASH_TEMPLATE = 'project-card-stash-user-id'
export const CATEGORIES_CONTACTS = [
  {
    title: 'Администратор Центра НТИ (решение оперативных вопросов)',
  },
  {
    title: 'Заместитель руководителя ЦК НТИ',
  },
  {
    title: 'И.о. руководителя Центра НТИ',
  },
  {
    title: 'Лицо уполномоченное подписывать договор',
  },
  {
    title: 'Лицо, наделенное полномочиями представлять Центр НТИ при взаимодействии со СМИ',
  },
  {
    title:
      'Лицо, ответственное за подготовку отчетной документации по договору предоставления гранта (решение оперативных вопросов)',
  },
  {
    title:
      'Лицо, уполномоченное на подписание отчетности по договору и вести официальную переписку',
  },
  {
    title: 'Лицо, уполномоченное на подписание отчетности по договору',
  },
  {
    title:
      'Лицо, уполномоченное подписывать договор о предоставлении гранта и доп. соглашения к договору',
  },
  {
    title:
      'Лицо, уполномоченное представлять интересы Центра НТИ в рамках проведения переговоров с потенциальными индустриальными партнерами Центра НТИ',
  },
  {
    title: 'Лицо, уполномоченное представлять Центр НТИ при взаимодействии со СМИ',
  },
  {
    title: 'Официальный e-mail',
  },
  {
    title: 'Руководитель образовательного направления',
  },
  {
    title: 'Руководитель образовательного направления (кандидатура уточняется)',
  },
  {
    title: 'Руководитель образовательного подразделения ',
  },
  {
    title: 'Руководитель Центра НТИ',
  },
]
export const CONSORTIA_UNIQUE = [
  {
    title: 'Да',
    id: 1,
    value: true,
  },
  {
    title: 'Нет',
    id: 0,
    value: false,
  },
]

export const NTI_INFORMATION_TABS = {
  CONSORTIA: 3,
  EMPLOYEE: 4,
  GRANTEE_CONTACTS: 5,
}
export const CONSORTIA_MEMBERS_TYPE = [
  {
    value: 'Образовательная организация',
  },
  {
    value: 'Научная организация',
  },
  {
    value: 'Коммерческая компания',
  },
  {
    value: 'Базовая организация',
  },
]
export const CONSORTIA_LAW_FORM = [
  {
    value: 'АО',
  },
  {
    value: 'ООО',
  },
  {
    value: 'ПАО',
  },
  {
    value: 'ФБУН',
  },
  {
    value: 'ФГАОУ ВО',
  },
  {
    value: 'ФГБНУ',
  },
  {
    value: 'ФГБОУ ВО',
  },
  {
    value: 'ФГБУ',
  },
  {
    value: 'ФГБУН',
  },
  {
    value: 'Фонд',
  },
]

export const CATEGORY_BUSINESS_ENTITY = [
  {
    value: 'Крупное предприятие',
  },
  {
    value: 'Среднее предприятие',
  },
  {
    value: 'Малое предприятие',
  },
  {
    value: 'Микропредприятие',
  },
]
export const REPORT_PERIOD = [
  {
    title: '1 квартал',
  },
  {
    title: '2 квартал',
  },
  {
    title: '3 квартал',
  },
  {
    title: 'Год',
  },
]

export const ACTUALITY_OPTION = [
  {
    value: true,
    title: 'Актуален',
  },
  {
    value: false,
    title: 'Не актуален',
  },
]

export const SUPPORT_KIND = [
  {
    name: 'Грант',
    id: 'grant',
  },
  {
    name: 'Вклад в УК',
    id: 'uk-contrib',
  },
  {
    name: 'Взнос в НКО',
    id: 'nko-contrib',
  },
  {
    name: 'Выплата призовых',
    id: 'prize',
  },
  {
    name: 'Без поддержки',
    id: 'no-support',
  },
  {
    name: 'Иное',
    id: 'other',
  },
]

export const STATUS_CODE = [
  {
    name: 'Проект в стадии реализации / Конкурс объявлен',
    id: 'implementation',
  },
  {
    name: 'Проект приостановлен',
    id: 'pause',
  },
  {
    name: 'Проект в стадии завершения',
    id: 'completion',
  },
  {
    name: 'Проект утвержден, готов к реализации',
    id: 'ready-to-implementation',
  },
  {
    name: 'Проект в стадии рассмотрения ',
    id: 'consideration',
  },
  {
    name: 'Проект в разработке',
    id: 'development',
  },
  {
    name: 'Проект без поддержки',
    id: 'no-support',
  },
  {
    name: 'Проект / Конкурс завершен',
    id: 'done',
  },
  {
    name: 'Проект в стадии постпроектного мониторинга',
    id: 'monitoring',
  },
]

export const PROJECT_REQUEST_STATUSES = {
  DRAFT: 'draft',
  NEW: 'new',
  REJECTED: 'rejected',
  IMPROVED: 'improved',
  SEND_FOR_APPROVE: 'send-for-approve',
  REVIEWED: 'reviewed',
  ACCEPTED: 'accepted',
}

export const REPORTS_STATUSES = {
  DRAFT: 'draft',
  NEW: 'new',
  IMPROVED: 'improved',
  REJECTED: 'rejected',
  SEND_FOR_APPROVE: 'send-for-approve',
  REVIEWED: 'reviewed',
  ACCEPTED: 'accepted',
  VERIFIED: 'verified',
  READY_TO_FINALIZE_CONCLUSION: 'ready-to-finalize-conclusion',
  FINALIZED_CONCLUSION: 'finalized-conclusion',
  REPEAT_FINALIZE_CONCLUSION: 'repeat-finalize-conclusion',
}

export const REPORT_STATUS_LABELS = {
  [REPORTS_STATUSES.DRAFT]: 'Черновик',
  [REPORTS_STATUSES.NEW]: 'Проверка Менеджером',
  [REPORTS_STATUSES.IMPROVED]: 'Повторная проверка Менеджером',
  [REPORTS_STATUSES.REJECTED]: 'Доработка отчета',
  [REPORTS_STATUSES.SEND_FOR_APPROVE]: 'Проверка монитором',
  [REPORTS_STATUSES.REVIEWED]: 'Сформировано заключение монитора',
  [REPORTS_STATUSES.ACCEPTED]: 'Отчет принят',
  [REPORTS_STATUSES.VERIFIED]: 'Отчёт проверен Фондом НТИ',
  [REPORTS_STATUSES.READY_TO_FINALIZE_CONCLUSION]: 'Сформировано экспресс-заключение',
  [REPORTS_STATUSES.FINALIZED_CONCLUSION]: 'Формирование итогового заключения',
  [REPORTS_STATUSES.REPEAT_FINALIZE_CONCLUSION]: 'Повторное формирование итогового заключения',
}

export const REPORT_TYPES = [
  {
    title: 'Первичный',
    id: 0,
  },
  {
    title: 'Уточненный',
    id: 1,
  },
]

export const REPORTS_TYPES = {
  FIRST_QUARTER: 'first-quarter',
  SECOND_QUARTER: 'second-quarter',
  THIRD_QUARTER: 'third-quarter',
  ANNUAL: 'annual',
}

export const REPORTS_TYPES_LABEL = {
  [REPORTS_TYPES.FIRST_QUARTER]: '1 квартал',
  [REPORTS_TYPES.SECOND_QUARTER]: '2 квартал',
  [REPORTS_TYPES.THIRD_QUARTER]: '3 квартал',
  [REPORTS_TYPES.ANNUAL]: 'Годовой отчет',
}

export const REPORTS_IDS = {
  FIRST_QUARTER: '1',
  SECOND_QUARTER: '2',
  THIRD_QUARTER: '3',
  ANNUAL: '4',
}

export const REPORTS_TIME_STATUSES = {
  SOON: 'BLACK',
  PENDING: 'GREEN',
  IN_PROGRESS: 'YELLOW',
  LATE: 'RED',
}
