import { axiosSupport } from '@/plugins/axios'

export class Feedback {
  getListCategoriesProject(project) {
    const params = new URLSearchParams({
      project,
    }).toString()

    return axiosSupport.get(`/themes?${params}`).then((response) => response.data)
  }

  sendFeedbackForm(data) {
    const headers = {
      'Content-type': 'multipart/form-data',
    }

    return axiosSupport.post('/messages', data, { headers })
  }
}
