<template>
  <TheContainer>
    <UsersTable
      :users="filteredUsers"
      :items="filteredUsers"
      :total-count="total"
      :unique-users="uniqueUsersList"
      :group-list="groupList"
      @update-usertable="loadData"
      @update-page="loadPage"
      @update-search="updateSearch"
    />
  </TheContainer>
</template>

<script setup>
import TheContainer from '@/components/TheContainer'
import { computed, onMounted, ref } from 'vue'
import UsersTable from '@/components/Users/UsersTable'
import { useStore } from 'vuex'

const store = useStore()

const page = ref(1)
let filters = ref({})

const filteredUsers = computed(() => store.getters['user/allFilteredUsers'])
const uniqueUsersList = computed(() => store.getters['user/allUniqueUsersList'])
const total = computed(() => store.getters['user/totalCount'])

const groupList = computed(() => store.getters['group/group'])
async function loadPage(page) {
  try {
    await store.dispatch('user/loadPage', page)
  } catch (err) {
    console.error(err)
  }
}

async function loadData(customPage = 1, pageSize = 20, filters) {
  const cleanedFilters = Object.fromEntries(
    Object.entries(filters?.value ?? {}).filter(([key, value]) => value != null),
  )
  const data = {
    page: customPage,
    pageSize: pageSize,
    ...cleanedFilters,
  }

  await store.dispatch('user/getUsers', data)
}

async function updateSearch(field, value) {
  filters.value[field] = value

  try {
    console.log(page.value, 20, filters.value, '=====')
    await loadData(page.value, 20, filters)
  } catch (err) {
    console.error(err)
  }
}

onMounted(() => {
  loadData()
})
</script>
<script>
export default {
  name: 'UsersAndRolesView',
}
</script>
