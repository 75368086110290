<template>
  <v-col class="pa-0 ma-0">
    <v-select
      :id="`inputCustom${id}`"
      ref="select"
      :model-value="isSelectOtherField && hasOther ? 'Другое' : inputModel"
      :items="options"
      :item-title="itemTitle"
      :item-value="itemValue"
      :multiple="Boolean(multiple)"
      :hint="descr"
      :readonly="readOnly"
      :persistent-hint="Boolean(descr)"
      :rules="rulesCommon"
      :hide-details="hideDetails"
      :error-messages="errorMessages"
      variant="outlined"
      @update:model-value="setNewValue"
    >
      <template v-if="itemTitle && itemValue" #item="{ item: { raw } }">
        <v-list-item
          :disabled="formatPrimitiveChar(raw.disabled)"
          class="select__name"
          @click="setNewValue(raw[itemValue])"
        >
          {{ raw[itemTitle] }}
          <v-tooltip v-if="raw.description" activator="parent" location="top">
            <div v-html="raw.description" />
          </v-tooltip>
        </v-list-item>
      </template>
      <template v-if="hasOther" #append-item>
        <v-list-item class="select__name" @click="setOtherValue"> Другое </v-list-item>
      </template>
    </v-select>
    <div v-if="hasOther && isSelectOtherField" class="mt-2">
      <v-text-field
        v-model="customValue"
        :rules="rulesCommon"
        :readonly="readOnly"
        class="text-pre-wrap"
        color="primary"
        placeholder="Введите свое значение"
        variant="outlined"
        @change="handleOtherValueChange"
      />
    </div>
  </v-col>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { formatPrimitiveChar } from '@/utils/common'

const props = defineProps({
  ...baseProps,
  itemValue: {
    type: String,
    default: 'value',
  },
  itemTitle: {
    type: String,
    default: 'name',
  },
  hasOther: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(baseEmit)

const showCustomInput = ref(false)
const select = ref(null)
const customValue = ref('')
const isSelectOtherField = computed(
  () => !props.options.includes(inputModel.value) && inputModel.value,
)

function setOtherValue() {
  if (props.readOnly) {
    return
  }
  showCustomInput.value = true
  inputModel.value = 'Другое'
  select.value?.blur()
}

function handleOtherValueChange() {
  inputModel.value = customValue.value
  emit('change-value', { id: props.id, value: customValue.value })
}
function setNewValue(value) {
  if (value !== 'Другое') {
    showCustomInput.value = false
  } else {
    customValue.value = ''
  }

  if (props.readOnly) {
    return
  }
  inputModel.value = value
  // select.value.menu = false
  emit('change-value', { id: props.id, value })
}

const { rulesCommon, inputModel } = useForm(props, emit)

const setDefaultOtherValue = () => {
  if (
    isSelectOtherField.value &&
    props.hasOther &&
    props.default_value &&
    props.default_value !== 'Другое'
  ) {
    showCustomInput.value = true
    customValue.value = props.default_value
  }
}

watch(props, () => {
  setDefaultOtherValue()
})

onMounted(() => {
  nextTick(() => {
    setDefaultOtherValue()
  })
})
</script>

<style lang="scss" scoped>
.select {
  &__name {
    cursor: pointer;
    &:hover {
      background-color: rgba(224, 222, 222, 0.4);
      opacity: 0.9;
    }
  }
}
</style>
