// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export function useToast() {
  const defaultOptions = {
    transition: 'bounce',
    position: 'bottom-right',
    showCloseButton: true,
    swipeClose: true,
    showIcon: true,
    hideProgressBar: true,
  }

  const toast = {
    success: (title = '', description = '') => {
      createToast({ title, description }, { ...defaultOptions, type: 'success' })
    },
    error: (title = '', description = '') => {
      createToast({ title, description }, { ...defaultOptions, type: 'danger' })
    },
    info: (title = '', description = '') => {
      createToast({ title, description }, { ...defaultOptions, type: 'info' })
    },
    warning: (title = '', description = '') => {
      createToast({ title, description }, { ...defaultOptions, type: 'warning' })
    },
  }
  return { toast }
}
