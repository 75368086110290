<template>
  <ol class="stepper d-flex flex-column py-6 px-0">
    <li
      v-for="(point, index) in points"
      :key="point.id"
      :class="['stepper-item cursor-pointer', !index ? 'complete' : '']"
      @click="() => router.push(`/tasks/${point.id}`)"
    >
      <span class="stepper-counter">{{ index + 1 }}</span>
      <div class="stepper-item">
        <span>{{ point?.name }}</span>
      </div>
      <span class="stepper-line"></span>
    </li>
  </ol>
</template>
<script setup>
import { useRouter } from 'vue-router/dist/vue-router'
defineProps({
  points: {
    type: Array,
    default: () => [],
  },
})

const router = useRouter()
</script>
<style lang="scss">
.stepper {
  gap: 26px;
}
.stepper-item {
  display: grid;
  grid-template-rows: [text-row] auto [line-row] 20px;
  grid-template-columns: [counter-column] 28px [text-column] auto;
  column-gap: 16px;
  row-gap: 8px;
  position: relative;
  &.complete {
    .stepper-counter {
      background-color: var(--backgroud-pending);
      color: var(--blue-selected-color);
      position: relative;
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--background-thead-table);
        border: 1px solid var(--blue-selected-color);
        right: -6px;
        bottom: -6px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='%231d4ed8' d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'/%3E%3C/svg%3E");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  &:last-child {
    grid-template-rows: [text-row] auto;
  }
}

.stepper-line {
  display: block;
  width: 2px;
  background-color: #8f8f8f;
  height: 100%;
  justify-self: center;
  position: absolute;
  bottom: -25px;
  left: 14px;
  z-index: 0;

  .stepper-item:last-child & {
    display: none;
  }
}
.stepper-counter {
  flex-shrink: 0;
  counter-increment: stepper;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: var(--blue-selected-color);
  color: #fff;
  border-radius: 50%;
  line-height: 1;
}

.stepper-item {
  display: flex;
  gap: 12px;
  text-decoration: none;
  color: var(--blue-selected-color);

  span {
    padding-top: calc((28px - 1.5em) / 2);
    font-weight: 600;
    border-bottom: 2px solid transparent;
  }
}
</style>
