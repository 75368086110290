import { axiosCustom } from '@/plugins/axios'

export class Auth {
  authorize() {
    const params = new URLSearchParams({
      client_id: process.env.VUE_APP_OAUTH_KEY,
      redirect_uri: process.env.VUE_APP_BASE_URL_OAUTH + process.env.VUE_APP_REDIRECT_URI,
      response_type: 'code',
    }).toString()

    return `https://leader-id.ru/api/oauth/authorize?${params}`
  }

  getAccessToken(code) {
    const params = new URLSearchParams({
      code: code,
    }).toString()

    return axiosCustom.get(`/api/user/setcode?${params}`).then((response) => response.data)
  }
}
