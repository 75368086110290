<template>
  <div class="login-page">
    <main class="main">
      <div class="main__form">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
}
</script>

<style lang="scss" scoped>
.main {
  margin: 0 auto;
  display: block;
  padding-bottom: 80px;

  &__form {
    text-align: center;
  }

  @media (max-width: 599.95px) {
    padding-bottom: 20px;
  }
}

.header {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;

  &__logo {
    cursor: pointer;
  }

  @media (max-width: 599.95px) {
    padding-top: 20px;
    padding-bottom: 20px;
    &__logo {
      height: 20px;
    }
  }
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
