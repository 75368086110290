const state = {
  projectId: null,
  hasActiveZNI: true,
  centerNti: null,
  certificates: [],
}

const getters = {
  projectId(state) {
    return state.projectId
  },
  centerNti(state) {
    return state.centerNti
  },
  certificates(state) {
    return state.certificates
  },
}

const mutations = {
  setCertificates(state, payload) {
    state.certificates = payload
  },
}

export const project = {
  namespaced: true,
  state,
  getters,
  mutations,
}
