import { axiosBase } from '@/plugins/axios'

export class Questionnaires {
  getTemplateFile(id) {
    return axiosBase
      .get(`/v2/projects/download-temp-file/${id}`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  getQuestionnaireById(code1C) {
    return axiosBase.get(`/v2/projects/application/${code1C}`).then((response) => response.data)
  }

  getParsingData(attachmentCode, fieldIndex) {
    return axiosBase
      .post(`/v2/projects/get-file-values`, {
        attachementCode: attachmentCode,
        fieldIndex: fieldIndex,
      })
      .then((response) => response.data)
  }

  saveApplication(code1C, data) {
    return axiosBase
      .post(`/v2/projects/application/${code1C}`, data)
      .then((response) => response.data)
  }

  saveApplicationNote(data) {
    return axiosBase.post('/v2/projects/notes', data).then((response) => response.data)
  }

  getApplicationNotes() {
    return axiosBase.get('/v2/projects/notes').then((response) => response.data)
  }

  getDataByIIN(inn) {
    return axiosBase
      .get(`/v2/projects/project-point/company-inn/${inn}`)
      .then((response) => response.data)
  }

  checkFieldValidity(fieldCode, value) {
    return axiosBase
      .post(`/v2/projects/project-point/value-validate`, { fieldCode, value })
      .then((response) => response.data)
  }

  getProjectsList() {
    return axiosBase.get('/v2/projects/project').then((response) => response.data)
  }

  getProgramsList() {
    return axiosBase.get('/v2/projects/programm').then((response) => response.data)
  }

  getProjectsById(id) {
    return axiosBase.get(`/v2/projects/project/${id}`).then((response) => response.data)
  }

  saveQuestionnaire(code1C, data) {
    return axiosBase
      .post(`/v2/projects/application/${code1C}`, data)
      .then((response) => response.data)
  }

  parsingQuestionnaireTemplateFile(id, data) {
    return axiosBase.post(`/v2/projects/parce-file/${id}`, data).then((response) => response.data)
  }

  declineParseFile(data) {
    console.log(data)
    return axiosBase.post('/v2/projects/decline-file', data).then((response) => response.data)
  }

  acceptParseFile(data) {
    return axiosBase.post('/v2/projects/accept-file', data).then((response) => response.data)
  }
}
