import { axiosCustom } from '@/plugins/axios'

export class FormFields {
  getFieldsByFormID(formId, page = 1, pageSize = 20) {
    const params = new URLSearchParams({
      form_id: formId,
      page,
      pageSize,
    }).toString()
    return axiosCustom.get(`/api/formfields?${params}`).then((response) => response.data)
  }

  createFields(form) {
    return axiosCustom.post('/api/formfields', form).then((response) => response.data)
  }

  updateFields(id, form) {
    return axiosCustom.put(`/api/formfields/${id}`, form).then((response) => response.data)
  }

  deleteFields(id) {
    return axiosCustom.delete(`/api/formfields/${id}`).then((response) => response.data)
  }

  getRules() {
    return axiosCustom.get('/api/formfields/getrules').then((response) => response.data)
  }
}
