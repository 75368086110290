import { createStore } from 'vuex'
import { auth } from '@/store/auth'
import { user } from '@/store/user'
import { group } from '@/store/group'
import { project } from '@/store/project'
import { reports } from '@/store/reports'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    group,
    project,
    reports,
  },
})
