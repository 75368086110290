import {
  ADMIN,
  ADMIN_COMPETITION,
  EXPERT,
  MANAGER,
  MONITOR,
  WINNER,
  MONITOR_CONTENT,
  USER,
} from '@/enums/roles'

export const roles = {
  [MANAGER]: [
    'programs-list',
    'programs-view-data',
    'tasks-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'create-project-by-code-1c',
    'project-preview',
    'edit-project',
    'view-project',
  ],
  [ADMIN]: ['usersList', 'personal-account', 'callback', 'default'],
  [ADMIN_COMPETITION]: [],
  [MONITOR]: ['tasks-list', 'personal-account', 'callback', 'default', 'task-by-id'],
  [EXPERT]: [
    'programs-list',
    'programs-view-data',
    'tasks-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'project-preview',
    'edit-project',
    'view-project',
  ],
  [WINNER]: [],
  [USER]: [
    'programs-list',
    'personal-account',
    'callback',
    'default',
    'task-by-id',
    'tasks-list',
    'programs-view-data',
    'create-project-by-code-1c',
    'project-preview',
    'edit-project',
    'view-project',
  ],
  [MONITOR_CONTENT]: ['tasks-list', 'personal-account', 'callback', 'default', 'task-by-id'],
}
