<template>
  <the-container>
    <v-expansion-panels v-model="panel" class="flat-panel tasks-tabs" accordion>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="font-weight-bold text-body-1">
          Заполнение формы анкеты получателя поддержки
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-6">
          <ProjectForm />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </the-container>
</template>
<script setup>
import TheContainer from '@/components/TheContainer'
import ProjectForm from '@/components/Project/ProjectForm.vue'
import { ref } from 'vue'

const panel = ref([0])
</script>

<style lang="scss">
.form-container {
  max-width: 850px;
  width: 100%;
}
</style>
