import { api } from '@/api/Api'

const state = {
  reports: [],
  currentReport: null,
}

const getters = {
  reports(state) {
    return state.reports
  },
  currentReport(state) {
    return state.currentReport
  },
}

const actions = {
  async getReports({ commit }, reportType) {
    try {
      const reports = await api.reports().getReports(reportType)
      if (reports) {
        commit('setReports', reports)
      }
    } catch (e) {
      console.log(e)
    }
  },
}

const mutations = {
  setReports(state, value) {
    state.reports = value
  },

  setCurrentReport(state, value) {
    state.currentReport = value
  },
}

export const reports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
