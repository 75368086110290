import { Auth } from '@/api/Auth'
import { Contest } from '@/api/Contest'
import { User } from '@/api/User'
import { Group } from '@/api/Group'
import { Result } from '@/api/Result'
import { Form } from '@/api/Form'
import { FormFields } from '@/api/FormFields'
import { Message } from '@/api/Message'
import { Upload } from '@/api/Upload'
import { Feedback } from '@/api/Feedback'
import { Notifications } from '@/api/Notifications'
import { Projects } from '@/api/Projects'
import { Information } from '@/api/Information'
import { Stash } from '@/api/Stash'
import { Reports } from '@/api/Reports'
import { Files } from '@/api/Files'
import { Balance } from '@/api/balance'
import { Tickets } from '@/api/Tickets'
import { Sign } from '@/api/Sign'
import { Questionnaires } from '@/api/Questionnaires'
import { Programs } from '@/api/Programs'
import { Expert } from '@/api/Expert'

class Api {
  auth() {
    return new Auth()
  }

  expert() {
    return new Expert()
  }

  information() {
    return new Information()
  }

  contest() {
    return new Contest()
  }

  user() {
    return new User()
  }

  group() {
    return new Group()
  }

  result() {
    return new Result()
  }

  form() {
    return new Form()
  }

  formFields() {
    return new FormFields()
  }

  message() {
    return new Message()
  }

  upload() {
    return new Upload()
  }

  feedback() {
    return new Feedback()
  }

  notifications() {
    return new Notifications()
  }

  projects() {
    return new Projects()
  }

  stash() {
    return new Stash()
  }

  reports() {
    return new Reports()
  }

  files() {
    return new Files()
  }

  balance() {
    return new Balance()
  }

  tickets() {
    return new Tickets()
  }

  sign() {
    return new Sign()
  }

  questionnaires() {
    return new Questionnaires()
  }

  programs() {
    return new Programs()
  }
}

export const api = new Api()
