import { axiosWinnerUser } from '@/plugins/axios'

export class Information {
  getConsortium(id) {
    return axiosWinnerUser.get(`/v1/projects/consortiums/${id}`).then((response) => response.data)
  }
  getConsortiums() {
    return axiosWinnerUser.get('/v1/projects/consortiums').then((response) => response.data)
  }

  createConsortiums(data) {
    return axiosWinnerUser.post('/v1/projects/consortiums', data).then((response) => response.data)
  }

  updateConsortiums(data, id) {
    return axiosWinnerUser
      .put(`/v1/projects/consortiums/${id}`, data)
      .then((response) => response.data)
  }

  deleteConsortiums(id) {
    return axiosWinnerUser
      .delete(`/v1/projects/consortiums/${id}`)
      .then((response) => response.data)
  }

  getEmployees(centerNtiId) {
    const params = new URLSearchParams({
      centerNtiId,
    }).toString()
    return axiosWinnerUser
      .get(`/v1/projects/employees?${centerNtiId ? params : ''}`)
      .then((response) => response.data)
  }

  getEmployee(id) {
    return axiosWinnerUser.get(`/v1/projects/employees/${id}`).then((response) => response.data)
  }

  deleteEmployee(id) {
    return axiosWinnerUser.delete(`/v1/projects/employees/${id}`).then((response) => response.data)
  }

  createEmployee(data) {
    return axiosWinnerUser.post('/v1/projects/employees', data).then((response) => response.data)
  }

  updateEmployee(data, id) {
    return axiosWinnerUser
      .put(`/v1/projects/employees/${id}`, data)
      .then((response) => response.data)
  }

  getGranteeContact(id) {
    return axiosWinnerUser
      .get(`/v1/projects/grantee-contacts/${id}`)
      .then((response) => response.data)
  }

  getGranteeContacts() {
    return axiosWinnerUser.get(`/v1/projects/grantee-contacts`).then((response) => response.data)
  }

  deleteGranteeContact(id) {
    return axiosWinnerUser
      .delete(`/v1/projects/grantee-contacts/${id}`)
      .then((response) => response.data)
  }

  createGranteeContact(data) {
    return axiosWinnerUser
      .post('/v1/projects/grantee-contacts', data)
      .then((response) => response.data)
  }

  updateGranteeContact(data, id) {
    return axiosWinnerUser
      .put(`/v1/projects/grantee-contacts/${id}`, data)
      .then((response) => response.data)
  }

  downloadConsortiumsTemplate() {
    return axiosWinnerUser
      .get('/v1/projects/consortiums/excel-template', { responseType: 'blob' })
      .then((response) => response.data)
  }

  downloadEmployeesTemplate() {
    return axiosWinnerUser
      .get('/v1/projects/employees/excel-template', { responseType: 'blob' })
      .then((response) => response.data)
  }

  downloadGranteeContactTemplate() {
    return axiosWinnerUser
      .get('/v1/projects/grantee-contacts/excel-template', { responseType: 'blob' })
      .then((response) => response.data)
  }

  importFileGranteeContact(data) {
    return axiosWinnerUser
      .post('/v1/projects/grantee-contacts/import', data)
      .then((response) => response.data)
  }

  importConsortiums(data) {
    return axiosWinnerUser
      .post('/v1/projects/consortiums/import', data)
      .then((response) => response.data)
  }

  importEmployees(data) {
    return axiosWinnerUser
      .post('/v1/projects/employees/import', data)
      .then((response) => response.data)
  }

  exportGranteeContacts() {
    return axiosWinnerUser
      .get('/v1/projects/grantee-contacts/export', { responseType: 'blob' })
      .then((response) => response.data)
  }

  exportEmployees(centerNtiId) {
    return axiosWinnerUser
      .get(`/v1/projects/employees/export?${centerNtiId ? `centerNtiId=${centerNtiId}` : ''}`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  exportConsortiums() {
    return axiosWinnerUser
      .get('/v1/projects/consortiums/export', { responseType: 'blob' })
      .then((response) => response.data)
  }
}
