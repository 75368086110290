<template>
  <div>
    <div class="footer-container">
      <v-card
        elevation="3"
        :class="[
          'pa-0 ma-auto py-5',
          ($vuetify.display.lg || $vuetify.display.xl) && contest && hasMargin && isUser
            ? 'lg-footer'
            : '',
        ]"
      >
        <the-container style-container="background: none !important">
          <v-row
            class="pa-0 ma-0 justify-space-between flex-md-nowrap flex-md-row flex-column align-center align-md-start text-center text-md-left justify-center"
          >
            <v-col
              class="logo pa-0 ma-0 align-center align-md-start justify-center justify-md-start d-flex mb-6 mb-md-0"
              cols="3"
            >
              <router-link to="/">
                <SvgBase width="129" height="35">
                  <SvgLogo />
                </SvgBase>
              </router-link>
            </v-col>
            <v-col cols="5" class="w-100 pa-0 ma-0 d-flex flex-column text-caption flex-grow-1">
            </v-col>
            <v-col
              class="pa-0 ma-0 d-flex flex-column align-center align-md-start justify-end text-caption text-grey"
              cols="12"
              md="4"
              lg="4"
            >
              <div
                v-for="(link, index) in defaultAgreementLinks"
                :key="index"
                class="text-grey cursor-pointer"
                @click="() => openFile(link)"
              >
                {{ link.title }}
              </div>
              <div class="mt-4">© ФОНД НТИ, {{ getYear() }}</div>
            </v-col>
          </v-row>
        </the-container>
      </v-card>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import SvgBase from '@/components/Svg/SvgBase'
import SvgLogo from '@/components/Svg/SvgLogo'
import { useStore } from 'vuex'
import { api } from '@/api/Api'
import TheContainer from '@/components/TheContainer'
const store = useStore()

const defaultAgreementLinks = ref([
  {
    title: 'Пользовательское соглашение',
    href: '/Пользовательское соглашение.pdf',
  },
  {
    title: 'Политика конфиденциальности',
    href: '/Политика конфиденциальности.pdf',
  },
  {
    title: 'Инструкция пользователя',
    href: '/Руководство пользователя_ЕЛК.pdf',
  },
])
const hasMargin = ref(false)

const isUser = computed(() => store.getters['user/isUser'])
const contest = computed(() => store.getters['contest/contest'])

defineProps({
  tabs: {
    type: Array,
    default: () => [],
  },
})

function getYear() {
  const date = new Date()
  return date.getFullYear()
}

async function openFile(link) {
  let fileLink = link.href
  if (link.isBlobFile) {
    await api
      .upload()
      .getFile(link.href)
      .then((response) => {
        const fileData = URL.createObjectURL(response)
        fileLink = fileData
        window.open(fileLink, '_blank').focus()
      })
  } else {
    window.open(fileLink, '_blank').focus()
  }
}

// function goToElk1() {
//   window.open(process.env.VUE_APP_ELK1_URL, '_blank')
// }
</script>
<style lang="scss">
.footer-container {
  .lg-footer {
    margin-bottom: 92px !important;
  }
}
</style>
