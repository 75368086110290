import { axiosWinnerUser } from '@/plugins/axios'

export class Balance {
  sendListFundsReturnedToGrantor(reportId, year, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/grantor-refundable/${year}`, data)
      .then((response) => response.data)
  }

  sendListFundsByYears(reportId, year, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/balance/${year}`, data)
      .then((response) => response.data)
  }

  getExpenseReport(reportId, year) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/grant-balance/${year}`)
      .then((response) => response.data)
  }
}
