<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <v-col v-else-if="program" cols="12" md="8" lg="8" class="py-8 pa-0 ma-0">
      <v-row class="pa-0 ma-0 flex-md-nowrap flex-wrap flex-column flex-md-row">
        <v-col class="pa-0 ma-0 text-formatter">
          <h3 class="mb-2" v-html="program?.name" />
          <div v-html="program?.description" />
          <div v-if="program?.importantInfo" class="mt-2 importantInfo">
            <div v-html="program?.importantInfo" />
          </div>
        </v-col>
      </v-row>

      <v-row
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 mt-2 mt-md-6 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Даты проведения:</v-col
        >
        <v-col cols="12" md="6" lg="6" class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2">{{
          date
        }}</v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 mt-2 mt-md-6 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Полное наименование конкурса:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.fullName"
        />
      </v-row>
      <v-row class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row">
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Требования к участникам конкурсного отбора:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.participantsRequirements || '-'"
        />
      </v-row>
      <v-row
        v-if="program.implementationRequirements"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Требования к реализации проекта участниками отбора:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.implementationRequirements || '-'"
        />
      </v-row>
      <v-row
        v-if="program.fundsRequirements"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Требования к расходованию бюджетных средств:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.fundsRequirements || '-'"
        />
      </v-row>
      <v-row
        v-if="program.cofinancingRequirements"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Требования к объему софинансирования проекта:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.cofinancingRequirements || '-'"
        />
      </v-row>
      <v-row
        v-if="program.supportLimit"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Предельный объем поддержки реализации проекта за счет средств субсидии:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="program.supportLimit || '-'"
        />
      </v-row>
      <v-row
        v-if="program.dateFillFrom"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Дата начала заполнения заявок:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="getFormatDateTime(program.dateFillFrom) || '-'"
        />
      </v-row>
      <v-row
        v-if="program.dateSubmitFrom"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Дата начала приема заявок:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="getFormatDateTime(program.dateSubmitFrom) || '-'"
        />
      </v-row>
      <v-row
        v-if="program.dateTo"
        class="pa-0 ma-0 border-bottom-line text-grey-darken-1 flex-column flex-md-row"
      >
        <v-col cols="12" md="6" lg="6" :class="['px-0 mx-0', (sm || xs) && 'font-weight-bold']"
          >Дата окончания приема заявок:</v-col
        >
        <v-col
          cols="12"
          md="6"
          lg="6"
          class="px-0 mx-0 pl-md-4 py-md-2 py-0 pb-2"
          v-html="getFormatDateTime(program.dateTo) || '-'"
        />
      </v-row>
      <div v-if="program.files?.length" class="mb-12">
        <div class="font-weight-bold text-grey my-6">
          Конкурсная документация и прочие материалы
        </div>
        <template v-if="!program?.preRegistration">
          <v-row
            v-for="item in program.files"
            :key="`${item.id}`"
            class="mx-0 px-0 w-100 word-break flex-nowrap"
          >
            <v-row
              class="text-body-2 px-0 font-weight-bold pr-2 mx-0 flex-nowrap my-0 py-0 mb-4"
              cols="10"
            >
              <v-icon color="grey">{{ getFileIcon(item?.name) }}</v-icon>
              <div class="ml-2">
                <div>{{ item?.name }}</div>
                <div>{{ item?.description }}</div>
              </div>
            </v-row>
            <v-col class="px-0 mx-0 justify-end d-flex pa-0" cols="2">
              <v-btn
                class="text-decoration-underline text-body-1 text-blue-darken-4"
                variant="text"
                @click="() => downloadFile(item)"
              >
                Скачать
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-row v-else class="px-0 mx-0">
          <v-alert
            text="Материалы будут доступны после предварительной регистрации"
            type="info"
            variant="tonal"
          />
        </v-row>
      </div>
      <v-row class="pa-0 ma-0 mt-12 align-center">
        <v-btn
          v-if="notComeDateFillFrom && (isManager || isUser)"
          color="primary"
          @click="goProgramPageForm"
        >
          Перейти к заполнению
        </v-btn>
        <v-col class="pa-0 ma-0 ml-6">
          <RouterLink :to="`/programs/`"> Закрыть </RouterLink>
        </v-col>
      </v-row>
    </v-col>
  </the-container>
</template>
<script setup>
import TheContainer from '@/components/TheContainer'
import { useRoute } from 'vue-router/dist/vue-router'
import { api } from '@/api/Api'
import { computed, onMounted, ref } from 'vue'
import moment from 'moment/moment'
import {
  downloadFileWithLinkData,
  getFileIcon,
  getFormatDateTime,
  getFormatDateDefault,
} from '@/helpers'
import { useToast } from '@/composables/useToast'
import { useRouter } from 'vue-router'

import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'

const { sm, xs } = useDisplay()
const route = useRoute()
const router = useRouter()
const store = useStore()
const { toast } = useToast()

const isManager = computed(() => store.getters['user/isManager'])
const isUser = computed(() => store.getters['user/isUser'])
const notComeDateFillFrom = computed(() =>
  moment(program.value.dateFillFrom).isValid()
    ? moment(new Date()).isAfter(program.value.dateFillFrom)
    : true,
)
const program = ref()
const isLoading = ref(false)

const date = computed(() => {
  if (program.value?.dateFrom && program.value?.resultDateTo) {
    return `${getFormatDateDefault(program.value?.dateFrom)} - ${getFormatDateDefault(
      program.value?.resultDateTo,
    )}`
  }

  return '-'
})

function goProgramPageForm() {
  return router.push(`/programs/create/${program.value?.code1C}`)
}

async function getProgramData() {
  isLoading.value = true
  try {
    await api
      .programs()
      .getActiveTemplateById(route.params.id)
      .then((data) => {
        program.value = data
      })
  } catch (err) {
    if (err?.response?.status === 404) {
      // toast.error('Форма не найдена')
      window.location.href = '/'
    }
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function downloadFile(file) {
  try {
    await downloadFileWithLinkData(file, file.name)
  } catch (err) {
    toast.error(err)
  }
}

onMounted(() => {
  getProgramData()
})
</script>
<style lang="scss" scoped>
.importantInfo {
  padding: 4px 8px;
  border-radius: 4px;
  background: #e7402c;
  color: white;
}
</style>
<style>
.text-formatter {
  ol,
  ul {
    padding-left: 30px;
  }
}
</style>
