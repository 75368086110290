import { axiosBase } from '@/plugins/axios'

export class Files {
  uploadFile(bucket, data) {
    return axiosBase.post(`/v1/store/file/${bucket}`, data).then((response) => response.data)
  }

  uploadSignFile(data) {
    return axiosBase
      .post(`/v2/projects/project-point/upload-attach`, data)
      .then((response) => response.data)
  }

  saveSignFile(id, sign) {
    return axiosBase.post(`/v2/projects/project-point/sign-attach`, {
      id,
      sign,
    })
  }

  getFiles(bucket, requestParams) {
    const params = requestParams ? new URLSearchParams({ ...requestParams }).toString() : ''
    return axiosBase.get(`/v1/store/bucket/${bucket}?${params}`).then((response) => response.data)
  }

  getFileByPointId(pointId) {
    return axiosBase
      .get(`/v2/projects/project-point/list-attach?filter[pointId]=${pointId}`)
      .then((response) => response.data)
  }

  getSignedFiles(requestParams) {
    const params = requestParams ? new URLSearchParams({ ...requestParams }).toString() : ''
    return axiosBase
      .get(`/v2/projects/project-point/list-attach?${params}`)
      .then((response) => response.data)
  }

  getBuckets() {
    return axiosBase.get('/v1/store/bucket').then((response) => response.data)
  }

  downloadFile(id) {
    return axiosBase.get(`/v1/store/file/${id}`).then((response) => response.data)
  }

  downloadZipProject(id) {
    // yana
    return axiosBase
      .get(`/v2/projects/project-point/zip/${id}`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  getBase64File(id) {
    return axiosBase
      .get(`/v2/projects/project-point/download-attach/${id}?txt=Y`)
      .then((response) => response.data)
  }

  downloadProgramFile(id) {
    return axiosBase.get(`/v2/projects/download-file/${id}`).then((response) => response.data)
  }

  deleteFile(id) {
    return axiosBase.delete(`/v1/store/file/${id}`).then((response) => response.data)
  }

  deleteSignedFile(id) {
    return axiosBase
      .delete(`/v2/projects/project-point/delete-attach/${id}`)
      .then((response) => response.data)
  }
}
