<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <v-col v-else class="py-8 pa-0 ma-0">
      <h3 class="mb-6">Текущие конкурсы</h3>
      <v-row class="align-stretch">
        <v-col
          v-for="template in templates"
          :key="template.id"
          cols="12"
          sm="12"
          md="4"
          class="ma-0"
        >
          <v-card elevation="2" class="pa-4 h-100 d-flex flex-column justify-lg-space-between">
            <v-row class="pa-0 ma-0 justify-start mb-6">
              <v-chip v-if="template.status" variant="tonal" size="small">
                {{ template.status }}
              </v-chip>
            </v-row>
            <div>
              <v-col class="pa-0 ma-0 flex-grow-1 font-weight-bold">
                <div>{{ template.name }}</div>
              </v-col>
              <v-row class="pa-0 ma-0 d-flex align-end">
                <v-col class="pa-0 ma-0 flex-grow-1 mt-2" cols="8">
                  <template v-if="template.dateFrom && template.dateTo">
                    <v-col class="pa-0 ma-0 text-caption font-weight-bold">
                      Проведение конкурса:
                    </v-col>
                    <v-col class="pa-0 ma-0 text-caption text-grey-darken-1">
                      {{ getFormatDate(template.dateFrom) }} - {{ getFormatDate(template.dateTo) }}
                    </v-col>
                  </template>
                </v-col>

                <v-col class="pa-0 ma-0 flex-grow-1 mt-2" cols="8">
                  <template v-if="template.resultDateFrom && template.resultDateTo">
                    <v-col class="pa-0 ma-0 text-caption font-weight-bold">
                      Подведение итогов:
                    </v-col>
                    <v-col class="pa-0 ma-0 text-caption text-grey-darken-1">
                      {{ getFormatDate(template.resultDateFrom) }} -
                      {{ getFormatDate(template.resultDateTo) }}
                    </v-col>
                  </template>
                </v-col>
                <v-col class="d-flex justify-end pa-0 ma-0" cols="4">
                  <RouterLink :to="`/programs/${template.id || template.code1C}`"
                    >Подробнее
                  </RouterLink>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </the-container>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import TheContainer from '@/components/TheContainer'
import { api } from '@/api/Api'
import moment from 'moment'

const isLoading = ref(false)
const templates = ref([])

async function getTemplates() {
  try {
    isLoading.value = true

    await api
      .programs()
      .getActiveTemplates()
      .then((data) => (templates.value = data))
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function getFormatDate(date) {
  return moment(date).format('DD.MM.YYYY')
}

onMounted(() => {
  getTemplates()
})
</script>
