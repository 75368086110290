<template>
  <div :key="selected" class="checkbox">
    <div v-if="descr" class="v-input__details">
      <div class="v-messages">
        <div class="v-messages__message" v-html="descr" />
      </div>
    </div>
    <v-container
      v-for="(option, idx) in options"
      :key="option.value"
      class="checkbox-wrapper"
      fluid
    >
      <v-checkbox
        v-model="selected"
        :rules="rulesCommon"
        :label="option.name"
        :readonly="readOnly"
        :value="option.value"
        :disabled="formatPrimitiveChar(option.disabled)"
        color="primary"
        density="compact"
      />
      <v-list-item
        v-if="option.description"
        :class="{
          'checkbox__description--last': options.length - 1 === idx,
        }"
        class="text-caption checkbox__description"
        v-html="option.description"
      />
    </v-container>
    <div class="text-red">{{ errorMessages }}</div>
  </div>
</template>

<script setup>
import { props as baseProps, emit as baseEmit } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { formatPrimitiveChar } from '@/utils/common'
import { onMounted, onUpdated, ref, watch } from 'vue'

const props = defineProps({
  ...baseProps,
})

const emit = defineEmits(baseEmit)

const selected = ref([])

const { rulesCommon, inputModel } = useForm(props, emit)

const initData = () => {
  if (props.default_value) {
    inputModel.value = props.default_value
    selected.value = props.default_value
  }
}

watch(selected, () => {
  inputModel.value = selected.value
  emit('change-value', { id: props.id, default_value: selected.value })
})

onUpdated(initData)
onMounted(initData)
</script>

<style lang="scss">
.checkbox {
  margin-bottom: 20px;

  &__description {
    padding: 0;
    margin-top: -20px;
    margin-left: 40px;
    &--last {
      margin-bottom: 20px;
    }
  }

  .v-input__details {
    display: none;
  }
}

.checkbox-wrapper {
  .v-selection-control {
    display: flex;
    align-items: flex-start;

    .v-selection-control__wrapper {
      margin-right: 20px;
      margin-top: 5px;
    }
  }
}
</style>
