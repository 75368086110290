<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <v-col v-else class="pa-0 ma-0">
    <v-row class="px-0 mx-0 pt-6 align-start mb-6">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        color="primary"
        class="flex-grow-1 mr-2"
        variant="solo"
        label="Название проекта, задачи"
      />
      <v-select
        v-model="template"
        :items="templatesOptions"
        item-title="name"
        item-value="code1C"
        label="Конкурс"
        color="primary"
        multiple
        variant="solo"
        hide-details="auto"
        persistent-hint
        style="width: 300px"
        class="flex-grow-0 mr-2 basic"
        return-object
      />
      <v-select
        v-model="status"
        :items="statusOptions"
        item-title="name"
        item-value="code1C"
        label="Статус"
        color="primary"
        multiple
        variant="solo"
        hide-details="auto"
        persistent-hint
        style="width: 300px"
        class="flex-grow-0 basic"
        return-object
      />
    </v-row>
    <temlate v-if="expertises.length">
      <v-expansion-panels>
        <v-expansion-panel v-for="item in expertises" :key="`expertise-${item.id}`" class="mb-2">
          <v-expansion-panel-title class="font-weight-bold">
            {{ item.name }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-row
              class="pa-0 ma-0 align-md-center align-start justify-start justify-md-space-between flex-md-row flex-column"
            >
              <v-col class="pa-2 pa-md-0 ma-0 font-weight-bold" cols="12" md="4" lg="4">
                <div>{{ item.name }}</div>
                <div
                  v-if="item.description"
                  class="text-caption text-grey expertise-description"
                  v-html="item.description"
                />
              </v-col>
              <v-col class="pa-2 pl-4 pa-md-0 ma-0 text-grey text-center" cols="12" md="6" lg="6">
                Статус: {{ item?.status?.name }}
              </v-col>
              <v-col
                class="pa-2 pa-md-0 text-start text-md-end ma-0 font-weight-regular justify-start justify-md-end"
                cols="12"
                md="2"
                lg="2"
              >
                <RouterLink :to="`/tasks/${item.id}`">
                  {{ item.statusCode === POINTS_STATUSES.SUSPENDED ? 'Редактировать' : 'Перейти' }}
                </RouterLink>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </temlate>
    <v-row v-else class="justify-center align-center py-6 text-grey"> Список пуст </v-row>
  </v-col>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { api } from '@/api/Api'

import { POINTS_STATUSES } from '@/constants/points'

const isLoading = ref(false)
const expertises = ref([])
const search = ref('')
const status = ref()
const statusOptions = ref([])
const template = ref()
const templatesOptions = ref([])

const getExpert = async () => {
  //isLoading.value = true
  try {
    const params = {
      'filter[q]': search.value || '',
      'filter[status]': status.value?.map((el) => el.code1C) || [],
      'filter[template]': template.value?.map((el) => el.code1C) || [],
    }
    await api
      .expert()
      .getExpert(params)
      .then((data) => {
        expertises.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

const getPointStatuses = () => {
  try {
    api
      .projects()
      .getPointStatuses()
      .then((data) => {
        statusOptions.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

async function getTemplates() {
  try {
    isLoading.value = true

    await api
      .programs()
      .getTemplatesList()
      .then((data) => (templatesOptions.value = data))
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

watch([search, status, template], () => {
  getExpert()
})
onMounted(() => {
  getExpert()
  getPointStatuses()
  getTemplates()
})
</script>
<style lang="scss">
.expertise-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p {
    margin: 4px 0;
  }
}

.basic {
  .v-field__input {
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-select__selection-text {
    white-space: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
