export const props = {
  default_value: {
    type: [String, Number, Array, Boolean],
    default: null,
  },
  value: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  isPhone: {
    type: Boolean,
    default: false,
  },
  isUrl: {
    type: Boolean,
    default: false,
  },
  templateFileId: {
    type: Number,
    default: null,
  },
  templateLink: {
    type: String,
    default: '',
  },
  fileTypes: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: [String, Number],
    default: null,
  },
  type: {
    type: String,
    default: '',
  },
  errorMessages: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  rules: {
    type: [Array, String],
    default: null,
  },
  descr: {
    type: String,
    default: '',
  },
  note: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: [String, Number],
    default: null,
  },
  multiple: {
    type: Number,
    default: 0,
  },
  group_id: {
    type: [String, Number],
    default: null,
  },
  sort: {
    type: [String, Number],
    default: null,
  },
  hideDetails: {
    type: Boolean,
    default: false,
  },
}

export const emit = ['update-value']
