<template>
  <TheLoading v-if="isShowInject" />
  <div v-if="!isShowInject" class="container">
    <v-row>
      <v-col cols="auto">
        <h3>
          <slot name="title" />
        </h3>
      </v-col>
    </v-row>

    <slot />
  </div>
</template>

<script setup>
import TheLoading from '@/components/TheLoading'
import { useLoading } from '@/composables/useLoading'

const { isShowInject } = useLoading()
</script>
<script>
export default {
  name: 'TheContainer',
}
</script>

<style scoped lang="scss">
.container {
  width: 1174px;
  margin: 0 auto;
  display: block;
  padding-top: 24px !important;
  padding-bottom: 80px !important;

  & > h1 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: 1279.95px) {
    width: 100%;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
    & > h1 {
      margin-top: 16px;
    }
  }

  @media (max-width: 599.95px) {
    padding-left: 15px;
    padding-right: 15px;
    & > h1 {
      font-size: 24px !important;
      line-height: 28px;
      margin-bottom: 24px !important;
    }
  }
}

.container {
  &__title {
    margin-top: 24px;
    margin-bottom: 24px;
    color: #000000;
    font-size: 40px;
    font-weight: normal;
    line-height: 55px;
    letter-spacing: 0;
  }

  &__more-link {
    margin-top: 24px;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 1279.95px) {
      margin-top: 16px;
      margin-bottom: 32px;
    }
    @media (max-width: 599.95px) {
      height: inherit;
      margin-top: 0 !important;
      justify-content: center;
    }
  }
}
.more-link {
  &__text {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  }

  &__link {
    color: #1a2574;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    transition: 0.2s;
    align-items: center;
    text-decoration: none;
    background-color: transparent !important;

    &:hover {
      color: #2d3a96;
    }

    @media (max-width: 599.95px) {
      font-size: 14px;
      &:hover > svg > path {
        stroke: #2d3a96 !important;
      }
    }
    @media (max-width: 599.95px) {
      & > svg {
        margin-top: 2px;

        & > path {
          stroke: #1a2574;
        }
      }
    }
  }

  &__svg {
    height: 12px;
    margin-top: 3px;
    transition: 0.2s;
    margin-left: 10px;

    & > path {
      stroke: #1a2574;
    }
  }
}
.control {
  & > div:nth-child(1) {
    left: 0;
    width: 100%;
    position: absolute;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  & > div:nth-child(2) {
    height: 100%;
  }

  &__btn {
    height: 60px;
    min-width: 280px;
    box-shadow: none;
    border-radius: 2px;
    text-transform: initial;

    &:disabled {
      background: #f0f0f0;
    }
  }

  @media (max-width: 760px) {
    &__btn {
      width: 100%;
    }
  }
  @media (max-width: 599.95px) {
    &__btn {
      height: 44px;
      font-size: 14px;
    }
  }

  &__link {
    color: #1a2574;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    transition: 0.2s;
    margin-left: 64px;
    white-space: nowrap;
    text-decoration: none;
    text-transform: initial;

    & > span > svg {
      flex: 0 0 8px;
      max-width: 8px;
      margin-top: 3px;
      transition: 0.2s;
      margin-left: 16px;
    }

    &:hover {
      color: #2d3a96;
    }

    @media (max-width: 760px) {
      margin-top: 24px;
      margin-left: 0;
      display: flex;
      & > span {
        line-height: 27px !important;
      }
    }
    @media (max-width: 599.95px) {
      font-size: 14px;
      margin-top: 16px;
      & > span > svg {
        margin-top: 2px;
      }
      &:hover > span > svg > path {
        stroke: #2d3a96 !important;
      }
    }
  }

  &--scroll {
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: 20;
    position: fixed;

    & > div:nth-child(1) {
      box-shadow: 0 -3px 20px rgba(0, 0, 0, 0.05);
      background-color: #fff;
    }

    & > div:nth-child(2) {
      width: 1170px;
      margin: 0 auto;
    }

    @media (max-width: 1279.95px) {
      & > div:nth-child(2) {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    @media (max-width: 599.95px) {
      & > div:nth-child(2) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}
</style>
