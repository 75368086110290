import { axiosWinnerUser } from '@/plugins/axios'

export class Reports {
  createReport(data) {
    return axiosWinnerUser.post('/v1/report', data).then((response) => response.data)
  }

  updateReport(reportId, data) {
    return axiosWinnerUser.put(`/v1/report/${reportId}`, data).then((response) => response.data)
  }

  sendToManagerNewReport(reportId) {
    return axiosWinnerUser.patch(`/v1/report/${reportId}/new`).then((response) => response.data)
  }

  getGrantSpendingStructure(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/payment-orders-structure`)
      .then((response) => response.data)
  }

  getUpdatedDates(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/updated-dates`)
      .then((response) => response.data)
  }

  getReports(reportType) {
    const params = reportType ? `filter[type]=${reportType}` : ''
    return axiosWinnerUser.get(`/v1/report?${params}`).then((response) => response.data)
  }

  getReportData(reportId) {
    return axiosWinnerUser.get(`v1/report/${reportId}`).then((response) => response.data)
  }

  uploadAccountingRegistries(data, reportId) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/load-accounting-register`, data)
      .then((response) => response.data)
  }

  uploadRegisterPaymentOrdersForm(data, reportId) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/load-payment-order-register`, data)
      .then((response) => response.data)
  }

  submitAchievementOutcomeValuesForm(reportId, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/achievement-result-deviation-reasons`, data)
      .then((response) => response.data)
  }

  getAchievementResultStructure(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/achievement-result-structure`)
      .then((response) => response.data)
  }

  getAchievementOutcomeValues(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/achievement-result-deviation-reasons`)
      .then((response) => response.data)
  }

  getGeneralReportInfo(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/general-info`)
      .then((response) => response.data)
  }

  getDetailsReportData(reportId, year) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/expense-structure?${year ? `year=${year}` : ''}`)
      .then((response) => response.data)
  }

  getPaymentOrders(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/payment-orders`)
      .then((response) => response.data)
  }

  getAccountRegisterGeneral(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-general`)
      .then((response) => response.data)
  }

  getAccountRegisterEducation(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-education`)
      .then((response) => response.data)
  }

  getAccountRegisterLicenses(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-licenses`)
      .then((response) => response.data)
  }

  getAccountRegisterIncome(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-income`)
      .then((response) => response.data)
  }

  getAccountRegisterRid(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-rid`)
      .then((response) => response.data)
  }

  getAccountRegisterCoFinancing(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/account-register-co-financing`)
      .then((response) => response.data)
  }

  getHistoryReport(reportId) {
    return axiosWinnerUser.get(`/v1/report/${reportId}/history`).then((response) => response.data)
  }

  getHistoryReportsWinner() {
    return axiosWinnerUser
      .get('/v1/report/center-nti-report-history')
      .then((response) => response.data)
  }

  saveExpenseStructure(reportId, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/expense-structure`, data)
      .then((response) => response.data)
  }

  saveCoFinanceStructure(reportId, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/co-finance-structure`, data)
      .then((response) => response.data)
  }

  saveReportImplementationProgram(reportId, data) {
    return axiosWinnerUser
      .post(`/v1/report/${reportId}/implementation-program`, data)
      .then((response) => response.data)
  }

  getColorsReports(ids) {
    return axiosWinnerUser
      .get(`/v1/report/report-color/list?${ids}`)
      .then((response) => response.data)
  }

  getCoFinanceStructure(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/co-finance-structure`)
      .then((response) => response.data)
  }

  getImplementationProgram(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/implementation-program`)
      .then((response) => response.data)
  }

  sendReportForRevision(reportId, comment) {
    return axiosWinnerUser
      .patch(`/v1/report/${reportId}/reject`, { comment })
      .then((response) => response.data)
  }

  acceptReport(reportId) {
    return axiosWinnerUser.patch(`v1/report/${reportId}/accept`).then((response) => response.data)
  }

  sendExpressConclusion(reportId) {
    return axiosWinnerUser
      .patch(`/v1/report/${reportId}/ready-to-finalize-conclusion`)
      .then((response) => response.data)
  }

  sendToMonitorForRepeatFinalizeConclusion(reportId, comment) {
    return axiosWinnerUser
      .patch(`/v1/report/${reportId}/repeat-finalize-conclusion`, { comment: comment })
      .then((response) => response.data)
  }

  sendToMonitorForFinalizeConclusion(reportId, comment) {
    return axiosWinnerUser
      .patch(`/v1/report/${reportId}/finalize-conclusion`, { comment })
      .then((response) => response.data)
  }

  sendReportToMonitorForApproval(reportId, comment) {
    return axiosWinnerUser
      .patch(`/v1/report/${reportId}/send-for-approval`, { comment })
      .then((response) => response.data)
  }

  sendReportToManagerForReview(reportId, comment) {
    return axiosWinnerUser
      .post(`v1/report/${reportId}/review`, { comment })
      .then((response) => response.data)
  }

  printDetailsStructure(reportId, year) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/grant-balance/${year}/pdf`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  printAchievementResult(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/achievement-result-deviation-reasons/pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  printGrantBalance(reportId, year) {
    return axiosWinnerUser
      .get(`v1/report/${reportId}/grant-balance/${year}/pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  printExpenseStructure(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/expense-structure/pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  printCoFinanceStructure(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/co-finance-structure/pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  printImplementationProgram(reportId) {
    return axiosWinnerUser
      .get(`/v1/report/${reportId}/implementation-program/pdf`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }

  getReportStatus(reportId, type) {
    const params = new URLSearchParams({
      type,
    }).toString()
    const route = reportId
      ? `/v1/report/report-color/${reportId}`
      : `/v1/report/report-color?${params}`
    return axiosWinnerUser.get(route).then((response) => response.data)
  }

  getTemplateFile(templateType) {
    const params = templateType ? new URLSearchParams({ templateType }).toString() : ''

    return axiosWinnerUser
      .get(`v1/report/template?${params}`, {
        responseType: 'blob',
      })
      .then((response) => response.data)
  }
}
