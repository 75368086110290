import { computed } from 'vue'
import { commonValidationRules, maxFileSize } from '@/utils/common'

export function findRuleProperty(props, rul) {
  const rules = props.rules

  const isArray = rules?.every((item) => Array.isArray(item))

  if (isArray) {
    return rules.find((rule) => rule.some((i) => i === rul))
  }

  if (rules?.includes(rul)) {
    return rules
  }
}

export function useForm(props, emit) {
  const maxLength = computed(() => {
    if (!props.rules) {
      return false
    }
    const item = findRuleProperty(props, 'string')

    if (!item) {
      return ''
    }

    return item[1]?.max ?? ''
  })

  const fileTypes = computed(() => {
    if (!props.rules) {
      return true
    }
    const item = findRuleProperty(props, 'file')

    if (!item) {
      return []
    }

    return item[1]?.extensions ?? []
  })

  const fileSize = computed(() => {
    if (!props.rules) {
      return maxFileSize
    }
    const item = findRuleProperty(props, 'file')

    if (!item) {
      return maxFileSize
    }

    return item?.find((prop) => prop.maxSize)?.maxSize ?? maxFileSize
  })

  const rulesCommon = computed(() => {
    if (!props.rules) {
      return []
    }

    return props.rules.map((rule) => commonValidationRules[rule]).filter(Boolean) ?? []
  })

  const inputModel = computed({
    get() {
      return props.default_value
    },
    set(value) {
      emit('update-value', {
        value,
        id: props.id,
      })
    },
  })

  return { maxLength, rulesCommon, inputModel, fileTypes, fileSize }
}
