import { axiosCustom } from '@/plugins/axios'

export class Result {
  getResults(page = 1, status, pageSize = 20, formId) {
    let data = {
      page,
      pageSize,
    }

    if (status) {
      data = {
        ...data,
        status,
      }
    }

    if (formId) {
      data = {
        ...data,
        form_id: formId,
      }
    }
    const params = new URLSearchParams(data).toString()
    return axiosCustom.get(`/api/result?${params}`).then((response) => response.data)
  }

  getOrders(page = 1, pageSize = 20) {
    let data = {
      page,
      pageSize,
    }
    const params = new URLSearchParams(data).toString()
    return axiosCustom.get(`/api/application?${params}`).then((response) => response.data)
  }

  getOrder(idOrder) {
    return axiosCustom.get(`/api/application/${idOrder}`).then((response) => response.data)
  }

  getResultById(id) {
    return axiosCustom.get(`/api/result/${id}`).then((response) => response.data)
  }

  sendResultByForm(form) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axiosCustom.post('/api/result', form, { headers }).then((response) => response.data)
  }

  updateResult(id, form) {
    return axiosCustom.put(`/api/result/${id}`, form).then((response) => response.data)
  }

  setStatus(id, status) {
    const params = new URLSearchParams({
      id,
      status,
    }).toString()
    return axiosCustom.get(`/api/result/setstatus?${params}`).then((response) => response.data)
  }

  exportResultByFormId(form) {
    const params = new URLSearchParams(form).toString()
    return axiosCustom
      .get(`/api/result/export?${params}`, { responseType: 'blob' })
      .then((response) => response.data)
  }

  getActiveResultsByFormIdAndUserId(formId, userId) {
    const params = new URLSearchParams({
      form_id: formId,
      user_id: userId,
    }).toString()
    return axiosCustom.get(`/api/result?${params}`).then((response) => response.data)
  }
}
