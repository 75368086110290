<template>
  <the-container>
    <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
      <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
    </v-row>
    <v-col v-else-if="project" class="pb-0">
      <v-card class="mb-6" variant="tonal" color="grey-darken-1">
        <v-card-title>
          <h3 class="ma-0 title text-black">{{ project?.name }}</h3>
          <div class="pa-0 ma-0 text-caption text-black">
            {{ project?.description }}
          </div>
        </v-card-title>
      </v-card>
      <v-table>
        <tbody>
          <tr>
            <td class="font-weight-bold py-2">Программа</td>
            <td class="py-2">
              <div>
                <div class="text-subtitle-1">{{ project?.programm?.name }}</div>
                <div class="text-grey text-caption">{{ project?.programm?.description }}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold py-2">Тип проекта</td>
            <td class="py-2">
              <div>
                <div class="text-subtitle-1">{{ project?.projectType?.name }}</div>
                <div class="text-grey text-caption">{{ project?.projectType?.description }}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold py-2">Портфель</td>
            <td class="py-2">
              <div>
                <div class="text-subtitle-1">{{ project?.briefcase?.name }}</div>
                <div class="text-grey text-caption">{{ project?.briefcase?.description }}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold py-2">Статус</td>
            <td class="py-2">
              <div>
                <div class="text-subtitle-1">{{ project?.status?.name }}</div>
                <div class="text-grey text-caption">{{ project?.status?.description }}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold py-2">Проектные точки</td>
            <td class="py-2">
              <div>
                <vertical-stepper :points="project?.points" />
              </div>
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row class="px-0 align-center mx-0 mt-6">
        <v-btn
          class="ml-2 text-decoration-underline"
          color="primary"
          variant="text"
          @click="goBack"
        >
          Закрыть
        </v-btn>
      </v-row>
    </v-col>
  </the-container>
</template>
<script setup>
import { api } from '@/api/Api'
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/dist/vue-router'
import TheContainer from '@/components/TheContainer'
import VerticalStepper from '@/components/Common/VerticalStepper'

const route = useRoute()
const router = useRouter()

const isLoading = ref(false)
const project = ref(null)

function goBack() {
  return router.go(-1)
}

async function getProjectData() {
  try {
    isLoading.value = true
    const projectId = route.params.id
    await api
      .questionnaires()
      .getProjectsById(projectId)
      .then((data) => {
        project.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getProjectData()
})
</script>
