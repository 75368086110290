import axios from 'axios'
import { tokenService } from '@/services/token'
import router from '@/router'

const axiosCustom = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})

const axiosSupport = axios.create({
  baseURL: process.env.VUE_APP_SUPPORT_BASE_URL,
})

const axiosNotifications = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATION_BASE_URL,
})

const axiosWinnerUser = axios.create({
  baseURL: process.env.VUE_APP_BASE_USL_WINNER,
})

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
})

/** Auth token interceptors */
const authInterceptor = (config) => {
  config.headers.Authorization = `Bearer ${tokenService.getToken()}`
  return config
}

/** Adding the request interceptors */
axiosCustom.interceptors.request.use(authInterceptor)

axiosSupport.interceptors.request.use(authInterceptor)

axiosNotifications.interceptors.request.use(authInterceptor)

axiosWinnerUser.interceptors.request.use(authInterceptor)

axiosCustom.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === 401) {
      localStorage.removeItem('access_token')
      router.push('/').then()
    }
    return Promise.reject(error)
  },
)

axiosBase.interceptors.request.use(authInterceptor)

axiosWinnerUser.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === 401) {
      localStorage.removeItem('access_token')
      window.location.href = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REDIRECT_KEY_LK}`
    }
    return Promise.reject(error)
  },
)

axiosSupport.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === 401) {
      localStorage.removeItem('access_token')
      window.location.href = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REDIRECT_KEY_LK}`
    }

    return Promise.reject(error)
  },
)

axiosNotifications.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === 401) {
      localStorage.removeItem('access_token')
      window.location.href = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REDIRECT_KEY_LK}`
    }

    return Promise.reject(error)
  },
)

axiosBase.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    if (status === 401) {
      localStorage.removeItem('access_token')
      window.location.href = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_REDIRECT_KEY_LK}`
    }

    return Promise.reject(error)
  },
)

export { axiosCustom, axiosSupport, axiosNotifications, axiosWinnerUser, axiosBase }
