<template>
  <div :class="['w-100 date-wrapper position-relative d-block', hideDetails ? '' : 'mb-5']">
    <v-text-field
      :id="`field-${id}`"
      v-model="inputModel"
      :hint="descr"
      :persistent-hint="Boolean(descr)"
      :rules="rulesCommon"
      :readonly="readOnly"
      :error-messages="errorMessages"
      hide-details
      color="primary"
      variant="outlined"
      class="text-hide"
      @change="changeValueInput"
    />
    <Datepicker
      :id="`wrapper-${id}`"
      v-model="inputModel"
      locale="ru"
      cancel-text="Закрыть"
      select-text="Выбрать"
      menu-class-name="picker"
      input-class-name="input-wrapper"
      :rules="rulesCommon"
      :readonly="readOnly"
      :error-messages="errorMessages"
      :required="required"
      :enable-time-picker="false"
      :disabled="disabled"
      :year-picker="!!yearPicker"
      :month-picker="monthPicker"
      :max-date="maxDate"
      :format="getFormat()"
      :min-date="minDate"
      auto-apply
      prevent-min-max-navigation
      @update:model-value="changeValueInput"
    />
    <div v-if="errorMessages" class="text-caption text-error mt-1">{{ errorMessages }}</div>
  </div>
</template>
<script setup>
import Datepicker from '@vuepic/vue-datepicker'

import { emit as baseEmit, props as baseProps } from '@/composables/form/usePropsForm'
import { useForm } from '@/composables/form/useForm'
import { nextTick, onMounted } from 'vue'

const props = defineProps({
  ...baseProps,
  yearPicker: {
    type: Boolean,
    default: false,
  },
  monthPicker: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxDate: {
    type: String,
    default: null,
  },
  minDate: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(baseEmit)

const { rulesCommon, inputModel } = useForm(props, emit)

function getFormat() {
  if (props.yearPicker) {
    return 'yyyy'
  }

  if (!props.monthPicker) {
    return 'dd.MM.yyyy'
  }

  if (props.monthPicker) {
    return 'MM.yyyy'
  }

  return null
}

function changeValueInput(e) {
  console.log(e, '===')
  emit('change-value', { id: props.id, value: e })
}

onMounted(() => {
  nextTick(() => {
    const container = document.getElementById(`wrapper-${props.id}`)
    if (container) {
      const input = container.querySelector('input')
      if (input) {
        input.id = `inputCustom${props.id}`
      }
    }
  })
})
</script>
<style lang="scss">
.picker {
  .dp__button,
  .dp__cancel {
    display: none;
  }

  .dp__select {
    color: rgb(var(--primary-color));
  }
}

.dp__main {
  .input-wrapper {
    background: none;
    border-color: rgba(0, 0, 0, 0.3);
    min-height: 56px;
  }
}

.date-wrapper {
  display: flex;
  flex-direction: column-reverse;
  .text-hide {
    .v-input__control {
      display: none;
    }
    input {
      display: none;
    }
  }
  .v-input--error {
    & + div {
      .input-wrapper {
        border-color: rgb(var(--v-theme-error));
      }
    }
  }
}
</style>
