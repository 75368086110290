import { axiosBase } from '@/plugins/axios'
import { getStringForGetRequest } from '@/helpers'

export class Expert {
  getExpert(params) {
    return axiosBase
      .get(`/v2/projects/project-point/expert?${getStringForGetRequest(params)}`)
      .then((response) => response.data)
  }
}
