import { axiosBase } from '@/plugins/axios'

export class Stash {
  getStashData(hash) {
    return axiosBase.get(`v1/stash/${hash}`).then((response) => response.data)
  }

  saveFieldInStash(data) {
    return axiosBase.post('/v1/stash', data).then((response) => response.data)
  }

  clearStash(hash) {
    return axiosBase.delete(`/v1/stash/${hash}`).then((response) => response.data)
  }

  deleteField(hash, params) {
    return axiosBase
      .delete(`/v1/stash/${hash}/field`, { data: params })
      .then((response) => response.data)
  }
}
