<template>
  <div :class="classNames">
    <v-btn
      :id="id"
      :prepend-icon="prependIcon"
      variant="text"
      :class="`text-trans px-0 text-${color}`"
      @click="onClick"
    >
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default_value: '',
    },
    id: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: 'mdi-plus-box-outline',
    },
    color: {
      type: String,
      default: 'blue-darken-4',
    },
    action: {
      type: Function,
      default: () => {},
    },
    classNames: {
      type: String,
      default: 'd-block mb-6 mt-2',
    },
  },
  methods: {
    onClick() {
      this.action()
      this.$emit('update-value', { value: null, id: this.id })
    },
  },
}
</script>
