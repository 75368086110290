import { axiosBase } from '@/plugins/axios'

export class Programs {
  getTemplates() {
    return axiosBase.get('/v2/projects/template').then((response) => response.data)
  }
  getActiveTemplates() {
    return axiosBase.get('/v2/projects/template/active').then((response) => response.data)
  }

  getTemplatesList() {
    return axiosBase.get('/v2/projects/template/by-user-points').then((response) => response.data)
  }

  getActiveTemplateById(code1C) {
    return axiosBase.get(`/v2/projects/template/${code1C}`).then((response) => response.data)
  }

  getProgramData(code1C) {
    return axiosBase.get(`/v2/projects/programm/${code1C}`).then((response) => response.data)
  }
}
