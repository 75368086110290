import { axiosCustom } from '@/plugins/axios'

export class Form {
  getForms(page = 1, pageSize = 20, qwery) {
    const params = new URLSearchParams({
      page,
      pageSize,
      ...qwery,
    }).toString()
    return axiosCustom.get(`/api/form?${params}`).then((response) => response.data)
  }

  getFormById(id, query = '') {
    return axiosCustom.get(`/api/form/${id}${query}`).then((response) => response.data)
  }

  createForm(form) {
    return axiosCustom.post('/api/form', form).then((response) => response.data)
  }

  updateForm(id, form) {
    return axiosCustom.put(`/api/form/${id}`, form).then((response) => response.data)
  }

  duplicateForm(formId, contestId) {
    const params = new URLSearchParams({
      id: formId,
      contest_id: contestId,
    }).toString()
    return axiosCustom.get(`/api/form/duplicate?${params}`).then((response) => response.data)
  }
}
