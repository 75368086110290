import { createApp } from 'vue'
import Datepicker from '@vuepic/vue-datepicker'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PrimeVue from 'primevue/config'

import 'maz-ui/css/main.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/styles/main.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import { VueMaskDirective } from 'v-mask'

const vMaskV2 = VueMaskDirective
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
}

createApp(App)
  .component('Datepicker', Datepicker)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(PrimeVue)
  .directive('mask', vMaskV3)
  .use(moment)
  .mount('#app')
