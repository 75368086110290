<template>
  <DataTable
    v-model:filters="searchValue"
    v-bind="attributes"
    :value="items"
    :paginator="true"
    :rows="20"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
  >
    <template #empty>
      <v-row justify="center">
        <v-col cols="auto"> Данные не найдены </v-col>
      </v-row>
    </template>
    <template v-if="showSearchFilter" #header>
      <v-row justify="end">
        <v-col md="4" sm="6">
          <v-text-field
            v-model="searchValue['global'].value"
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            density="compact"
            hide-details="auto"
            placeholder="Поиск по таблице"
          />
        </v-col>
      </v-row>
    </template>
    <slot />
  </DataTable>
</template>

<script setup>
import DataTable from 'primevue/datatable'
import { ref } from 'vue'
import { FilterMatchMode } from 'primevue/api'

defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  attributes: {
    type: Object,
    default: () => {
      return {}
    },
  },
  showSearchFilter: {
    type: Boolean,
    default: false,
  },
})

const searchValue = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
})
</script>
<script>
export default {
  name: 'TheTable',
}
</script>
